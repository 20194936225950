import React from "react";
import "./index.scss";
import { NavLink, Switch, Route, Redirect } from "react-router-dom";
import {
  USER_MANAGEMENT_ROUTES,
  MOBILE_USER_ROUTES,
  VEHICLE_MANAGEMENT_ROUTES,
  FOREGROUND_APP_MANAGEMENT_ROUTES,
} from "constants/routes";

import VehicleManagement from "./Vehicles";
import UserManagement from "./Users";
import MobileUsersManagement from "./MobileUsers";
import ForegroundAppManagement from "./ForegroundApps"

const Management = () => {
  return (
    <div className="section-wrapper">
      <h3 className="section-title">Management</h3>
      <ul className="section-nav">
        <li>
          <NavLink to={VEHICLE_MANAGEMENT_ROUTES.ROOT}>Vehicles</NavLink>
        </li>
        <li>
          <NavLink to={USER_MANAGEMENT_ROUTES.ROOT}>Users</NavLink>
        </li>
        <li>
          <NavLink to={MOBILE_USER_ROUTES.ROOT}>Mobile Users</NavLink>
        </li>
        <li>
          <NavLink to={FOREGROUND_APP_MANAGEMENT_ROUTES.ROOT}>Allowed APPs</NavLink>
        </li>
      </ul>
      <div className="section-content">
        <Switch>
          <Route
            path={VEHICLE_MANAGEMENT_ROUTES.ROOT}
            component={VehicleManagement}
          />
          <Route
            path={USER_MANAGEMENT_ROUTES.ROOT}
            component={UserManagement}
          />
          <Route
            path={MOBILE_USER_ROUTES.ROOT}
            component={MobileUsersManagement}
          />
          <Route
            path={FOREGROUND_APP_MANAGEMENT_ROUTES.ROOT}
            component={ForegroundAppManagement}
          />
          <Redirect from="/" to={VEHICLE_MANAGEMENT_ROUTES.ROOT} />
        </Switch>
      </div>
    </div>
  );
};

export default Management;
