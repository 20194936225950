import React,{useEffect,useCallback,useState} from "react";
import {settingApi} from "api"
import "./index.scss";
import EditBeaconModal from "./EditBeaconModal";

const Beacon = ({ beacon,color,onBeaconUpdated,beaconIndex }) => {
    
    const [openEditModal,setOpenEditModal] = useState(false);

    return (<>
    <div className="beacon-card">
        <div style={{borderColor:color}}>
            <div className="b-field">
                <span>MAC Address</span>
                <span>{!!beacon ? beacon.macAddress : 'Null'}</span>
            </div>
            <div className="b-field">
                <span>Battery Level</span>
                <span >{!!beacon ? beacon.batteryLevel: 'Null'}</span>
            </div>
            <span className="b-action" onClick={()=>{setOpenEditModal(true)}}>
                <i className="glyphicon glyphicon-pencil"/>
            </span>
        </div>
    </div>
    {
        openEditModal && <EditBeaconModal beacon={beacon} onBeaconUpdated={onBeaconUpdated} 
        beaconIndex={beaconIndex}
        opened={openEditModal}
        setOpened={setOpenEditModal}/>
    }
    </>
    );
}

const Beacons = ({ beacons,onBeaconUpdated }) => {
    const [batteryLevels,setBatteryLevels] = useState([]);
    const getBatteryLevels = useCallback(()=>{
        settingApi.getBatteryLevels()
            .execute()
            .then((result)=>{
                console.log(result);
                if(!result.data.hasError){
                    const payload = result.data.payload;
                    const sorted = payload.sort((a,b)=>{
                        return b.maxLevel - a.maxLevel;
                    });
                    setBatteryLevels(sorted);
                }
            })
    },[]);

    useEffect(()=>{
        getBatteryLevels();
    },[getBatteryLevels]);

    const getColor = (beacon)=>{
        let color = "#000";
        if(!beacon) return color;
        for(let i of batteryLevels){
            if(beacon.batteryLevel <= i.maxLevel){
                color = i.color;
            }else{
                break;
            }
        }
        return color;
    }
    return (<div className="beacons-box">
        {
            beacons.map((b,ind) => (<Beacon beacon={b} key={ind} 
                    color={getColor(b)}
                    beaconIndex={ind}
                    onBeaconUpdated={onBeaconUpdated}/>))
        }
    </div>)
}

export default Beacons;