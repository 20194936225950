import { useEffect } from "react";

function useOutSideClickHandler(ref, callback) {
  useEffect(() => {
    function handleClickOutSide(ev) {
      if (ref.current && !ref.current.contains(ev.target)) {
        callback();
      }
    }
    document.addEventListener("mousedown", handleClickOutSide);
    return () => {
      document.removeEventListener("mousedown", handleClickOutSide);
    };
  }, [ref, callback]);
}

export default useOutSideClickHandler;
