import React, { useEffect, useState, useCallback } from "react";
import "./index.scss";
import {
    Modal,
    ModalHeader,
    ModalContent,
    ModalFooter,
} from "components/Modal";
import ShiftItem from "./ShiftItem";
import { hexUsersApi } from "api";
import { toast } from "shared/utils/toast";

const defaultData = [
    {
        day: "Mon",
        from: {
            hour: "",
            minute: "",
        },
        to: {
            hour: "",
            minute: "",
        },
    },
    {
        day: "Tue",
        from: {
            hour: "",
            minute: "",
        },
        to: {
            hour: "",
            minute: "",
        },
    },
    {
        day: "Wed",
        from: {
            hour: "",
            minute: "",
        },
        to: {
            hour: "",
            minute: "",
        },
    },
    {
        day: "Thu",
        from: {
            hour: "",
            minute: "",
        },
        to: {
            hour: "",
            minute: "",
        },
    },
    {
        day: "Fri",
        from: {
            hour: "",
            minute: "",
        },
        to: {
            hour: "",
            minute: "",
        },
    },
    {
        day: "Sat",
        from: {
            hour: "",
            minute: "",
        },
        to: {
            hour: "",
            minute: "",
        },
    },
    {
        day: "Sun",
        from: {
            hour: "",
            minute: "",
        },
        to: {
            hour: "",
            minute: "",
        },
    },
];

const ShiftHoursModal = ({
    opened,
    setOpened,
    onUpdate,
    oldData
}) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [apiMessage, setApiMessage] = useState("");

    const onClose = () => {
        setOpened(false);
    };

    const onCancelClicked = () => {
        setOpened(false);
    };

    const manipulateIncomingData = (iData) => {
        try {
            let tempList = [...defaultData];
            iData.forEach((i) => {
                const ind = tempList.findIndex((t) => t.day === i.day);
                tempList[ind] = {
                    day: i.day,
                    from: {
                        hour: i.from.split(":")[0],
                        minute: i.from.split(":")[1],
                    },
                    to: {
                        hour: i.to.split(":")[0],
                        minute: i.to.split(":")[1],
                    },
                };
            });
            setData([...tempList]);
        } catch {
            setData([...defaultData]);
        }
    };

    const getCurrentShiftHours = useCallback(() => {
        if(oldData && oldData.shiftHours){
            manipulateIncomingData(oldData.shiftHours);
        }else{
            setData([...defaultData]);
        }
    }, [oldData]);

    useEffect(() => {
        getCurrentShiftHours();
    }, [getCurrentShiftHours]);

    const onShiftItemChanged = (d) => {
        const tempData = [...data];
        const target = tempData.findIndex((t) => t.day === d.day);
        tempData[target] = {
            ...d,
        };
        setData([...tempData]);
    };

    useEffect(() => {
        console.log(data);
    }, [data]);

    const saveData = (finalItems)=>{
        console.log(finalItems);
        setLoading(true);
        setApiMessage("");
        hexUsersApi
            .saveShiftHours(oldData.id,finalItems)
            .execute()
            .then((result) => {
                if (result.data.hasError) {
                    setApiMessage(result.data.errorMessage || "API Error");
                } else {
                    toast.success("Shif Hours Changed");
                    onUpdate(oldData.id,finalItems);
                }
            })
            .finally(() => {
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const onSaveClicked = () => {
        const finalData = data.map((d) => {
            return {
                day: d.day,
                from: `${d.from.hour}:${d.from.minute}`,
                to: `${d.to.hour}:${d.to.minute}`,
            };
        });
        const filteredItems = [];
        finalData.forEach((fd) => {
            if (fd.from.length > 1) {
                filteredItems.push(fd);
            }
        });
        console.log("before save",filteredItems);
        saveData(filteredItems);
    };

    const onSaveDefaultClicked = ()=>{
        saveData(null);
    }

    return (
        <Modal open={opened} onClose={onClose} className="shift-hours-modal">
            <ModalHeader>User Shift Hours</ModalHeader>
            <ModalContent>
                <div className="hours-context">
                    {apiMessage && <div className="hours-message">{apiMessage}</div>}
                    <ul className="shift-items">
                        {data.map((d) => (
                            <li key={d.day}>
                                <ShiftItem data={d} onChange={onShiftItemChanged} />
                            </li>
                        ))}
                    </ul>
                </div>
            </ModalContent>
            <ModalFooter>
                <div className="actions">
                    <button
                        className="btn btn-primary"
                        type="button"
                        disabled={loading}
                        onClick={onSaveClicked}
                    >
                        Save
                    </button>
                    <button
                        className="btn btn-warning"
                        type="button"
                        disabled={loading}
                        onClick={onSaveDefaultClicked}
                    >
                        Save Default
                    </button>
                    <button className="btn btn-danger" onClick={onCancelClicked}>
                        Close
                    </button>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default ShiftHoursModal;
