const authApi = (axios, config, interceptorHandler, apiCaller) => {
  const axiosInstance = axios.create(config);
  interceptorHandler(axiosInstance);
  return {
    login({ mobileNumber, password }) {
      return apiCaller(axiosInstance, {
        method: "POST",
        url: "/login",
        data: {
          mobileNumber,
          password,
        },
      });
    },
    getProfile(access_token) {
      return apiCaller(axiosInstance, {
        method: "GET",
        url: "/profile",
        headers: {
          authorization: "Bearer " + access_token,
        },
      });
    },
    changePassword({ currentPassword, newPassword }) {
      return apiCaller(axiosInstance, {
        method: "PUT",
        url: "/change-password",
        headers: {
          authorization: "Bearer " + localStorage.getItem("access_token"),
        },
        data: {
          currentPassword,
          newPassword,
        },
      });
    },
    createUserByAdmin(user) {
      return apiCaller(axiosInstance, {
        method: "POST",
        url: "/create",
        headers: {
          authorization: "Bearer " + localStorage.getItem("access_token"),
        },
        data: user,
      });
    },
    getUsers(limit,offset) {
      return apiCaller(axiosInstance, {
        method: "GET",
        url: `/?limit=${limit}&offset=${offset}`,
        headers: {
          authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      });
    },
    sendForgotPasswordEmail(email){
      return apiCaller(axiosInstance, {
        method: "POST",
        url: `/forgot-password`,
        data:{
          email
        }
      });
    },
    resetPassword(token,newPassword){
      return apiCaller(axiosInstance, {
        method: "POST",
        url: `/reset-password`,
        data:{
          token,
          newPassword
        }
      });
    },
    lockUser(userId){
      return apiCaller(axiosInstance, {
        method: "PUT",
        url: `/lock-user/${userId}`,
        headers: {
          authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      });
    },
    deleteUser(userId){
      return apiCaller(axiosInstance, {
        method: "DELETE",
        url: `/${userId}`,
        headers: {
          authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      });
    },
    updateUserByAdmin(user){
      return apiCaller(axiosInstance, {
        method: "PUT",
        url: "/"+user.id,
        headers: {
          authorization: "Bearer " + localStorage.getItem("access_token"),
        },
        data: user,
      });
    },
    getUserByAdmin(userId){
      return apiCaller(axiosInstance, {
        method: "get",
        url: `/${userId}`,
        headers: {
          authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      });
    },
    updateProfile(user){
      return apiCaller(axiosInstance, {
        method: "PUT",
        url: "/profile",
        headers: {
          authorization: "Bearer " + localStorage.getItem("access_token"),
        },
        data: user,
      });
    },
    updateNotificationSettings(notificationSetting){
      return apiCaller(axiosInstance, {
        method: "PUT",
        url: "/profile/notification-setting",
        headers: {
          authorization: "Bearer " + localStorage.getItem("access_token"),
        },
        data: {
          notificationSetting
        },
      });
    }
  };
};

export default authApi;
