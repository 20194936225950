import React, { useEffect, useCallback, useState } from "react";
import { settingApi } from "api";


const availableZones = [{
    label:"",
    value:""
},{
    label:"UTC-10 US/Hawaii",
    value:"US/Hawaii"
},{
    label:"UTC-09 US/Aleutian",
    value:"US/Aleutian"
},{
    label:"UTC-08 America/Anchorage",
    value:"America/Anchorage"
},{
    label:"UTC-07 America/Los_Angeles",
    value:"America/Los_Angeles"
},{
    label:"UTC-07 America/Phoenix",
    value:"America/Phoenix"
},{
    label:"UTC-06 US/Mountain",
    value:"US/Mountain"
},{
    label:"UTC-05 America/Chicago",
    value:"America/Chicago"
},{
    label:"UTC-04 America/New_York",
    value:"America/New_York"
}]

const Zone = () => {
    const [loading, setLoading] = useState(false);
    const [zone, setZone] = useState("");
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");

    const getBlockedPolicy = useCallback(async () => {
        setLoading(true);
        settingApi
            .getActiveZone()
            .execute()
            .then((result) => {
                console.log(result);
                if (!result.data.hasError) {
                    setZone(result.data.payload.value);
                }
            })
            .finally(() => {
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        getBlockedPolicy();
    }, [getBlockedPolicy]);


    const onSaveClicked = async () => {
        setError("");
        if (zone) {
            setLoading(true);
            const result = await await settingApi.saveActiveZone(zone).execute();
            setLoading(false);
            if (result.data.hasError) {
                setError(result.data.message);
            } else {
                setMessage("Time Zone saved.")
            }

        } else {
            setError("Time Zone is required");
        }
    };

    const onZoneChanged = (e) => {
        console.log(e.target.value);
        setZone(e.target.value);
    }

    return (
        <>
            <div className="management-c-body">
                <div className="df-box">
                    <div className="box-body">
                        {
                            !!message && <div className="form-success-message">{message}</div>
                        }
                        <div
                            className="form-group"
                        >
                            <label htmlFor="zone">Default Time Zone</label>
                            <div>
                            <select value={zone} onChange={onZoneChanged} id="zone"
                                className="form-control">
                                {
                                    availableZones.map(az=>(
                                        <option value={az.value} key={az.value}>{az.label}</option>
                                    ))
                                }
                            </select>
                            </div>
                            {!!error && (
                                <span className="help-block">{error}</span>
                            )}
                        </div>
                        <div className="actions">
                            <button
                                className="btn btn-primary"
                                type="button"
                                disabled={loading}
                                onClick={onSaveClicked}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Zone;
