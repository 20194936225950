import * as Yup from "yup";

export const getUpdateProfileSchema = () => {
  return Yup.object({
    fullName: Yup.string().required("Required"),
    email: Yup.string()
      .required("Required")
      .email("Must be a valid Email address"),
    mobileNumber: Yup.string().required("Required")
  });
};
