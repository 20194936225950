import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { MAIN_ROUTES, SETTING_ROUTES, ACCOUNT_ROUTES } from "constants/routes";

import Management from "../pages/Management";
import Setting from "../pages/Setting";
import StatusLogs from "../pages/StatusLogs";
import StatusStatistics from "../pages/StatusStatistics";
import Account from "pages/Account";
import NotFound from "components/NotFound";
import MainNav from "./components/MainNav";

const Routes = () => {
  return (
    <>
      <MainNav />
      <div className="container-fluid">
        <Switch>
          <Route path={MAIN_ROUTES.MANAGEMENT} component={Management} />
          <Route path={SETTING_ROUTES.ROOT} component={Setting} />
          <Route path={MAIN_ROUTES.STATUS_STATISTICS} component={StatusStatistics} />
          <Route path={MAIN_ROUTES.STATUS_LOGS} component={StatusLogs} />
          <Route path={ACCOUNT_ROUTES.ROOT} component={Account} />
          <Redirect from="/panel" exact to={MAIN_ROUTES.MANAGEMENT} />
          <Route exact path="*" component={NotFound} />
        </Switch>
      </div>
    </>
  );
};

export default Routes;
