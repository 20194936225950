import React from "react";
import { NavLink, Switch, Route, Redirect } from "react-router-dom";
import { SETTING_ROUTES } from "constants/routes";

import BatterySetting from "./Battery";
import ShiftHoursSetting from "./ShiftHours";
import BlockedPolicy from "./BlockedPolicy";
import Zone from "./Zone";
import NotificationDetails from "./Notifications";

const Setting = () => {
  console.log("here");
  return (
    <div className="section-wrapper">
      <h3 className="section-title">Settings</h3>
      <ul className="section-nav">
        <li>
          <NavLink to={SETTING_ROUTES.BATTERY}>Battery Levels</NavLink>
        </li>
        <li>
          <NavLink to={SETTING_ROUTES.SHIFT_HOURS}>Shift Hours</NavLink>
        </li>
        <li>
          <NavLink to={SETTING_ROUTES.POLICIES}>Blocked Policy</NavLink>
        </li>
        <li>
          <NavLink to={SETTING_ROUTES.ZONE}>Time Zone</NavLink>
        </li>
        <li>
          <NavLink to={SETTING_ROUTES.NOTIFICATION_DETAILS}>Safety Alert Thresholds</NavLink>
        </li>
      </ul>
      <div className="section-content">
        <Switch>
          <Route path={SETTING_ROUTES.BATTERY} component={BatterySetting} />
          <Route
            path={SETTING_ROUTES.SHIFT_HOURS}
            component={ShiftHoursSetting}
          />
          <Route
            path={SETTING_ROUTES.POLICIES}
            component={BlockedPolicy}
          />
          <Route
            path={SETTING_ROUTES.ZONE}
            component={Zone}
          />
          <Route
            path={SETTING_ROUTES.NOTIFICATION_DETAILS}
            component={NotificationDetails}
          />
          <Redirect from="/panel/setting" to={SETTING_ROUTES.BATTERY} />
        </Switch>
      </div>
    </div>
  );
};

export default Setting;
