const settingApiCreator = (axios, config, interceptorHandler, apiCaller) => {
  const axiosInstance = axios.create(config);
  interceptorHandler(axiosInstance);

  return {
    getReminderIOS() {
      return apiCaller(axiosInstance, {
        method: "GET",
        url: "/reminder-ios",
        headers: {
          authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      });
    },
    getShiftHours() {
      return apiCaller(axiosInstance, {
        method: "GET",
        url: "/shift-hours",
        headers: {
          authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      });
    },
    saveShiftHours(data,remindIOS) {
      return apiCaller(axiosInstance, {
        method: "POST",
        url: "/shift-hours",
        headers: {
          authorization: "Bearer " + localStorage.getItem("access_token"),
        },
        data: {
          data,
          remindIOS
        },
      });
    },
    getBatteryLevels() {
      return apiCaller(axiosInstance, {
        method: "GET",
        url: "/battery-levels",
        headers: {
          authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      });
    },
    createBatteryLevel({ maxLevel, hint, color }) {
      return apiCaller(axiosInstance, {
        method: "POST",
        url: "/battery-levels",
        headers: {
          authorization: "Bearer " + localStorage.getItem("access_token"),
        },
        data: {
          maxLevel,
          hint,
          color,
        },
      });
    },
    editBatteryLevel({ id, maxLevel, hint, color }) {
      return apiCaller(axiosInstance, {
        method: "PUT",
        url: "/battery-levels",
        headers: {
          authorization: "Bearer " + localStorage.getItem("access_token"),
        },
        data: {
          id,
          maxLevel,
          hint,
          color,
        },
      });
    },
    deleteBatteryLevel(id) {
      return apiCaller(axiosInstance, {
        method: "GET",
        url: "/battery-levels/" + id,
        headers: {
          authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      });
    },
    getBlockedPolicy() {
      return apiCaller(axiosInstance, {
        method: "GET",
        url: "/blocked-policy",
        headers: {
          authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      });
    },
    saveBlockedPolicy(id) {
      return apiCaller(axiosInstance, {
        method: "POST",
        url: "/blocked-policy",
        data: {
          value: id
        },
        headers: {
          authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      });
    },
    getActiveZone() {
      return apiCaller(axiosInstance, {
        method: "GET",
        url: "/active-zone",
        headers: {
          authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      });
    },
    saveActiveZone(zone) {
      return apiCaller(axiosInstance, {
        method: "POST",
        url: "/active-zone",
        data: {
          value: zone
        },
        headers: {
          authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      });
    },
    getLogNotifications() {
      return apiCaller(axiosInstance, {
        method: 'GET',
        url: "/notification-data",
        headers: {
          authorization: "Bearer " + localStorage.getItem("access_token"),
        }
      })
    },
    updateLogNotification(id, title, value) {
      return apiCaller(axiosInstance, {
        method: "PUT",
        url: "/notification-data",
        data: {
          id, title, value
        },
        headers: {
          authorization: "Bearer " + localStorage.getItem("access_token"),
        }
      })
    }
  };
};

export default settingApiCreator;
