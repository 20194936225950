import React from "react";
import { useGlobal } from "globalContext";
const Home = () => {
  const { user } = useGlobal();
  return (
    <div className="row">
      <div className="col-xs-6">
        <h1>DistractFree Panel</h1>
        <h2>{user.fullName}</h2>
      </div>
      <div className="col-xs-6">test</div>
    </div>
  );
};

export default Home;
