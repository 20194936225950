import React from "react";

import { Switch, Route } from "react-router-dom";
import { VEHICLE_MANAGEMENT_ROUTES } from "constants/routes";

import VehicleList from "./VehicleList";
import NewVehicle from "./NewVehicle";
import VehicleDetails from "./VehicleDetails";

const VehicleManagement = () => {
  return (
    <div className="management-container">
      <Switch>
        <Route path={VEHICLE_MANAGEMENT_ROUTES.NEW} component={NewVehicle} />
        <Route path={VEHICLE_MANAGEMENT_ROUTES.EDIT} component={NewVehicle} />
        <Route
          path={VEHICLE_MANAGEMENT_ROUTES.VIEW}
          component={VehicleDetails}
        />
        <Route path={VEHICLE_MANAGEMENT_ROUTES.ROOT} component={VehicleList} />
      </Switch>
    </div>
  );
};

export default VehicleManagement;
