import Layout from "Layout";
import { GlobalProvider } from "globalContext";
import Home from "pages/Home";
import { BrowserRouter as Router } from "react-router-dom";
import { MAIN_ROUTES } from "constants/routes";
const App = () => {
  return (
    <Router basename={MAIN_ROUTES.ROOT}>
      <GlobalProvider>
        <Layout>
          <Home />
        </Layout>
      </GlobalProvider>
    </Router>
  );
};

export default App;
