import React, { useState, useEffect } from "react";
import TimePicker from "./TimePicker";

const ShiftItem = ({ data, onChange }) => {
  const [from, setFrom] = useState({ h: data.from.hour, m: data.from.minute });
  const [to, setTo] = useState({ h: data.to.hour, m: data.to.minute });

  useEffect(() => {
    if (!!from.h && !!to.h) {
      if (onChange) {
        onChange({
          ...data,
          from: {
            hour: from.h,
            minute: from.m,
          },
          to: {
            hour: to.h,
            minute: to.m,
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, to]);

  const onFromChanged = (h, m) => {
    setFrom({
      h,
      m,
    });
  };
  const onToChanged = (h, m) => {
    setTo({
      h,
      m,
    });
  };
  return (
    <div className="shift-item">
      <span className="s-day">{data.day}</span>
      <span className="s-time">
        <TimePicker data={data.from} onChange={onFromChanged} />
      </span>
      <span className="s-to">To</span>
      <span className="s-time">
        <TimePicker data={data.to} onChange={onToChanged} />
      </span>
    </div>
  );
};

export default ShiftItem;
