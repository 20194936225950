import React from "react";
import { Switch, Route } from "react-router-dom";
import { USER_MANAGEMENT_ROUTES } from "constants/routes";

import UserList from "./UserList";
import NewUser from "./NewUser";

const UserManagement = () => {
  return (
    <div className="management-container">
      <Switch>
        <Route path={USER_MANAGEMENT_ROUTES.ENROLL} component={NewUser} />
        <Route path={USER_MANAGEMENT_ROUTES.UPDATE} component={NewUser} />
        <Route path={USER_MANAGEMENT_ROUTES.ROOT} component={UserList} />
      </Switch>
    </div>
  );
};

export default UserManagement;
