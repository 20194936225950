import React from "react";

const Occupants = ({occupants})=>{
    return (<div className="df-table-wrapper">
        <table className="table table-responsive">
        <thead>
                <tr>
                  <th>Name</th>
                  <th>Mobile Number</th>
                  <th>Email</th>
                  <th>Status</th>
                  <th>Blocked</th>
                </tr>
              </thead>
              <tbody>
                  {
                      occupants.map(o=>(
                          <tr key={o.id}>
                              <td>{o.fullName}</td>
                              <td>{o.mobileNumber}</td>
                              <td>{o.email}</td>
                              <td>{o.status}</td>
                              <td>{o.blocked ? "Blocked" : "---"}</td>
                          </tr>
                      ))
                  }
              </tbody>
        </table>
    </div>)
}

export default Occupants;

