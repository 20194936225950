import React, { useState, useEffect, useCallback } from "react";

import { useHistory, useParams } from "react-router-dom";
import { FOREGROUND_APP_MANAGEMENT_ROUTES } from "constants/routes";

import { useFormik } from "formik";
import { getNewForegroundAppValidationSchema } from "./validationSchema";
import { foregroundAppApi, HTTP_STATUS_CODES } from "api";
import {toast} from "shared/utils/toast";

const NewApp = () => {
  const history = useHistory();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [apiErrors, setApiErros] = useState([]);
  const [apiMessage, setApiMessage] = useState("");
  const [initialValues, setInitialValues] = useState({
    appName:"",
    blackListed: "No"
  });

  const onBackClicked = () => {
    history.push(FOREGROUND_APP_MANAGEMENT_ROUTES.ROOT);
  };

  const getAppDetails = useCallback(() => {
    setLoading(true);
    foregroundAppApi
      .getForegroundAppById(id)
      .execute()
      .then((result) => {
        if (result.data.hasError) {
          history.replace(FOREGROUND_APP_MANAGEMENT_ROUTES.ROOT);
        } else {
          const payload = result.data.payload;
          setInitialValues({
            appName:payload.appName || "",
            blackListed:payload.blackListed ? "Yes" : "No"
          });
        }
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id) {
        getAppDetails();
    }
  }, [id, getAppDetails]);

  const saveForegroundApp = (apiFn, data) => {
    foregroundAppApi[apiFn]({ ...data })
      .execute()
      .then((result) => {
        if (result.data.hasError) {
          if (result.data.statusCode === HTTP_STATUS_CODES.VALIDATION_ERROR) {
            let validationErrors = [];
            Object.keys(result.data.errors).forEach((er) => {
              validationErrors = [
                ...validationErrors,
                ...result.data.errors[er],
              ];
            });
            setApiErros([...validationErrors]);
          } else {
            setApiErros([result.data.message]);
          }
        } else {
          if (id) {
            toast.success("Foreground App Updated successfully")
            history.push(FOREGROUND_APP_MANAGEMENT_ROUTES.ROOT);
          } else {
            setApiMessage("Foreground App created successfully");
            formik.resetForm();
          }
        }
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: getNewForegroundAppValidationSchema(),
    onSubmit: (values) => {
      if (!loading) {
        setApiErros([]);
        if(id){
          saveForegroundApp(
            "editForegroundApp",
            {
                ...values,
                id: id,
            }
          );
        }else{
          setLoading(true);
        saveForegroundApp(
          "createForegroundApp",
          {
            ...values,
            id: id,
          }
        );
        }
      }
    },
  });

  return (
    <>
      <div className="management-c-nav-actions">
        <button
          className="btn btn-default dropdown-toggle"
          type="button"
          onClick={onBackClicked}
        >
          Back to list
        </button>
      </div>
      <div className="management-c-body">
        <div className="df-box">
          <h3 className="box-title">Create new Foreground App</h3>
          <div className="box-body">
            {!!apiErrors.length && (
              <div>
                <ul className="form-errors">
                  {apiErrors.map((ae) => (
                    <li key={ae}>{ae}</li>
                  ))}
                </ul>
              </div>
            )}
            {!!apiMessage && (
              <div className="form-success-message">{apiMessage}</div>
            )}
            <form onSubmit={formik.handleSubmit}>
            <div
                className={`form-group ${
                  formik.touched.appName && formik.errors.appName ? "has-error" : ""
                }`}
              >
                <label htmlFor="currentPassword">Foreground App Name</label>
                <input
                  type="text"
                  id="appName"
                  name="appName"
                  className="form-control"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.appName}
                />
                {formik.touched.appName && formik.errors.appName && (
                  <span className="help-block">{formik.errors.appName}</span>
                )}
              </div>
              
              <div
                className={`form-group ${
                  formik.touched.model && formik.errors.model ? "has-error" : ""
                }`}
              >
                <label htmlFor="currentPassword">Black Listed</label>
                <div> 
                  <input
                      id="No"
                      type="radio"
                      value="No"
                      name="blackListed"
                      onChange={formik.handleChange}
                      checked={formik.values.blackListed === "No"}
                      style={{marginRight:5}}
                    />
                  <label htmlFor="currentPassword" style={{marginRight:10}}>No</label>
                  <input
                    id="Yes"
                    type="radio"
                    value="Yes"
                    name="blackListed"
                    onChange={formik.handleChange}
                    checked={formik.values.blackListed === "Yes"}
                    style={{marginRight:5}}
                  />
                  <label htmlFor="currentPassword">Yes</label>
                 </div>
                {formik.touched.model && formik.errors.model && (
                  <span className="help-block">{formik.errors.model}</span>
                )}
              </div>


              <div className="box-actions">
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={loading}
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewApp;
