import React, { useEffect } from "react";
import { useGlobal } from "globalContext";
import Login from "pages/Login";
import Container from "Layout/Container";
import {
  useHistory,
  useLocation,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { MAIN_ROUTES, LOGIN_ROUTES } from "constants/routes";
import NotFound from "components/NotFound";

const authRoutes = [
  MAIN_ROUTES.LOGIN,
  LOGIN_ROUTES.LOGIN,
  LOGIN_ROUTES.FORGOT_PASSWORD,
  LOGIN_ROUTES.RESET_PASSWORD,
];

const LayoutContent = () => {
  const { user } = useGlobal();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location && location.pathname) {
      if (!authRoutes.includes(location.pathname)) {
        if (!user) {
          history.replace(MAIN_ROUTES.LOGIN);
        }
      } else {
        if (user) {
          history.replace(MAIN_ROUTES.PANEL);
        }
      }
    } else {
      history.replace(LOGIN_ROUTES.LOGIN);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Switch>
      <Route path={MAIN_ROUTES.PANEL} component={Container} />
      <Route path={MAIN_ROUTES.LOGIN} component={Login} />
      <Redirect from="/" exact to={MAIN_ROUTES.LOGIN} />
      <Route exact path="*" component={NotFound} />
    </Switch>
  );
};

const Layout = ({ children }) => {
  const { loading } = useGlobal();

  return loading ? <div>Loading...</div> : <LayoutContent />;
};

export default Layout;
