import React, {
    createContext,
    useContext,
    useState,
    useRef
} from "react";
import useOutSideClickHandler from "shared/hooks/handlClickOutside";
import "./index.scss";

export const ActionsContext = createContext({});

export const ActionsProvider = ({ children }) => {
    const userMenuRef = useRef(null);
    const [dropDownOpened, setDropDownOpened] = useState(false);
    useOutSideClickHandler(userMenuRef, () => {
        setDropDownOpened(false);
    });

    const onHandlerClicked = () => {
        setDropDownOpened(!dropDownOpened);
    }



    return (
        <ActionsContext.Provider value={{ dropDownOpened, setDropDownOpened }}>
            <div className="actions-drop-down" ref={userMenuRef}>
                <div className="handler" onClick={onHandlerClicked}>
                    <span className="glyphicon glyphicon-option-vertical" />
                </div>
                <div className={`drop-down ${dropDownOpened ? "open" : ""}`}>
                    <div className="drop-down-items">
                        {children}
                    </div>
                </div>
            </div>
        </ActionsContext.Provider>
    );
};

export const useActions = () => {
    const actions = useContext(ActionsContext);
    if (!actions) {
        throw new Error("useActions should be inside ActionsProvider");
    }
    return actions;
};
