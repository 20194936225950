import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from "components/Modal";

const DeleteModal = ({
  opened,
  setOpened,
  userId,
  apiDeleteFn,
  onSuccess,
  targetEntity
}) => {
  const [loading, setLoading] = useState(false);
  const [error,setError] = useState("");

  const onClose = () => {
    setOpened(false);
  };

  const onCancelClicked = () => {
    setOpened(false);
  };

  const onDeleteClicked = ()=>{
    setError("");
        setLoading(true);
        
        apiDeleteFn(userId)
            .execute()
            .then((result)=>{
                if(result.data.hasError){
                    setError(result.data.message);
                }else{
                    onSuccess(userId)
                    setOpened(false);
                }
            })
            .catch((err)=>{
                console.log(err);
                setLoading(false);
            })
    
  }

  return (
    <Modal open={opened} onClose={onClose} className="create-modal">
      <ModalHeader>Delete {targetEntity}</ModalHeader>
        <ModalContent>
          <div className="df-box in-modal policy-modal">
            <div style={{textAlign:"center"}}>
            <div>This {targetEntity} will be deleted with your approval.</div>
            <div>Are you sure?</div>
            </div>
            {
                !!error && (
                    <>
                    <hr />
                    <div style={{textAlign:"center",color:"red"}}>{error}</div>
                    </>
                )
            }
          </div>
        </ModalContent>
        <ModalFooter>
          <div className="actions">
            <button
              className="btn btn-primary"
              type="button"
              disabled={loading}
              onClick={onDeleteClicked}
            >
              Yes
            </button>
            <button className="btn btn-danger" onClick={onCancelClicked}>
              No
            </button>
          </div>
        </ModalFooter>
    </Modal>
  );
};

export default DeleteModal;
