import React, { useEffect, useCallback, useState } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import { VEHICLE_MANAGEMENT_ROUTES } from "constants/routes";

import { vehicleApi, settingApi } from "api";
import Pagination from "components/Pagination";
import { PAGE_RECORDS } from 'constants/pagination';

import ActionsDropDown from "components/ActionsDropDown";
import ActionItem from "components/ActionsDropDown/ActionItem";

import DeleteModal from "components/DeleteModal";
import { toast } from "shared/utils/toast";

const VehicleList = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [minimumLevel, setMinimumLevel] = useState(0);
  const params = new URLSearchParams(useLocation().search);
  const currentUrlPage = params.get("page");
  const currentPage = currentUrlPage && parseInt(currentUrlPage) ? parseInt(currentUrlPage) : 1;
  const [pageCount, setPageCount] = useState(1);

  const [deletingVehicleId,setDeletingVehicleId] = useState(-1);
  const [openDeleteModal,setOpenDeleteModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");


  const onEnrollClicked = () => {
    history.push(VEHICLE_MANAGEMENT_ROUTES.NEW);
  };

  const getVehicles = useCallback(async () => {
    let limit = PAGE_RECORDS;
    let offset = (currentPage - 1) * PAGE_RECORDS;
    setLoading(true);
    settingApi.getBatteryLevels()
      .execute()
      .then((res) => {
        if (!res.data.hasError) {
          const payload = res.data.payload;
          const sorted = payload.sort((a, b) => {
            return a.maxLevel - b.maxLevel;
          });
          if (sorted.length) {
            setMinimumLevel(sorted[0].maxLevel);
          }
        }
      })
      .finally(() => {
        vehicleApi
          .getVehicles(limit, offset)
          .execute()
          .then((result) => {
            if (!result.data.hasError) {
              setVehicles(result.data.payload.vehicles);
              const totalCount = result.data.payload.totalCount;
              if (totalCount) {
                if (totalCount > PAGE_RECORDS) {
                  setPageCount(Math.ceil(totalCount / PAGE_RECORDS))
                }
              }
            }
          })
          .finally(() => {
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      })

  }, [currentPage]);

  useEffect(() => {
    getVehicles();
  }, [getVehicles]);

  const getPrettyDate = (d) => {
    return (new Date(d)).toDateString()
  }

  const getMaintAlert = (beacons) => {
    let hasAlert = false;
    if (beacons) {
      for (let b of beacons) {
        if (b && b.macAddress && b.batteryLevel < minimumLevel) {
          hasAlert = true;
          break;
        }
      }
    }
    return hasAlert ? <span style={{ color: "red" }}>Yes</span> : <span>No</span>;
  }

  const onPageChanged = (page) => {
    history.push(`${VEHICLE_MANAGEMENT_ROUTES.ROOT}?page=${page}`);
  }

  const onDeleteSuccess = (vehicleId)=>{
    const tempVehicles = [...vehicles]
    const currentVehicle = tempVehicles.findIndex(v=>v.id === vehicleId);
    tempVehicles.splice(currentVehicle,1);
    setVehicles([...tempVehicles]);
    toast.success("Vehicle Deleted Sucessfully");
  }

  const onDeleteClicked = (sender)=>{
    setDeletingVehicleId(sender);
    setOpenDeleteModal(true);
  }

  const handleFilter = () => {
    vehicleApi
          .vehicleSearch(searchTerm)
          .execute()
          .then((result) => {
            if (!result.data.hasError) {
              setPageCount("");
              setVehicles(result.data.payload.vehicles);
            }
          })
          .finally(() => {
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
    };
  const clearFilter = () => {
    setSearchTerm("");
    getVehicles();
  }

  return (
    <>
      <div className="management-c-nav-actions">
        <button
          className="btn btn-default dropdown-toggle"
          type="button"
          onClick={onEnrollClicked}
        >
          Add Vehicle
        </button>
      </div>
      <div style={{marginTop:10, marginBottom:10}}>
        <input className='filterInput' onChange={event => 
        {setSearchTerm(event.target.value)}} value= {searchTerm}></input>
        <button className='filterSearchButton' onClick={handleFilter} style={{marginLeft:10}}>Find by Beacon</button>
        <button className='clearSearchButton' onClick={clearFilter} style={{marginLeft:10}}>Clear</button>
      </div>
      <div className="management-c-body">
        <div className="df-table-wrapper">
          {loading ? (
            <div>Loading...</div>
          ) : (
            <>
              <table className="table table-responsive ">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Manufacturer</th>
                    <th>Model</th>
                    <th>Year</th>
                    <th>License Plate</th>
                    <th>Beacons</th>
                    <th>Trained</th>
                    <th>Color</th>
                    <th>Maint Alert</th>
                    <th>Created At</th>
                    <th>Has CarPlay</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {vehicles.map((v) => (
                    <tr key={v.id}>
                      <td>{v.vehicleName}</td>
                      <td>{v.make}</td>
                      <td>{v.model}</td>
                      <td>{v.productionYear}</td>
                      <td>{v.licensePlate}</td>
                      <td>{v.beacons ? v.beacons.length : '--'}</td>
                      <td>{v.trained ? "True" : "False"}</td>
                      <td>{v.color}</td>
                      <td>{getMaintAlert(v.beacons)}</td>
                      <td>{getPrettyDate(v.createdAt)}</td>
                      <td>{v.hasCarPlay ? "Yes" : "No"}</td>
                      <td>
                          <ActionsDropDown>
                            <ActionItem onClick={() => { }}>
                              <Link
                                to={`/panel/manage/vehicles/${v.id}/edit`}
                                className="lnk"
                              >
                                Edit
                              </Link>
                            </ActionItem>
                            <ActionItem onClick={() => { }}>
                              <Link
                                to={`/panel/manage/vehicles/${v.id}`}
                                className="lnk"
                              >
                                View
                              </Link>
                            </ActionItem>
                            <ActionItem onClick={() => { onDeleteClicked(v.id) }}>Delete</ActionItem>
                          </ActionsDropDown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {
                (pageCount > 1) && <Pagination currentPage={currentPage} pageCount={pageCount} onPageChanged={onPageChanged} />
              }
              {
                  openDeleteModal && <DeleteModal 
                  opened={openDeleteModal}
                  setOpened={setOpenDeleteModal}
                  userId={deletingVehicleId}
                  onSuccess={onDeleteSuccess}
                  targetEntity="vehicle"
                  apiDeleteFn={vehicleApi.deleteVehicle}/>
                }
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default VehicleList;
