import React from "react";
import { useActions } from "./ActionsContext";

const ActionItem = ({children,onClick})=>{
    const {setDropDownOpened}= useActions();
    const onItemClicked = ()=>{
        setDropDownOpened(false);
        onClick();
    }
    return <div className="d-action-item" onClick={onItemClicked}>{children}</div>
}

export default ActionItem;