import React, { useEffect, useCallback, useState } from "react";

import PolicyModal from "./PolicyModal"
import ShiftHoursModal from "./ShiftHoursModal";
import { hexUsersApi } from "api";
import { PAGE_RECORDS } from 'constants/pagination';
import Pagination from "components/Pagination";
import { useHistory, useLocation, Link } from "react-router-dom";
import { MOBILE_USER_ROUTES } from "constants/routes";
import ActionsDropDown from "components/ActionsDropDown";
import ActionItem from "components/ActionsDropDown/ActionItem";

import DeleteModal from "components/DeleteModal";
import { toast } from "shared/utils/toast";

const UserList = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [currentUpdatingUser, setCurrentUpdatingUser] = useState(null);
  const [openShiftHours, setOpenShiftHours] = useState(false);
  const [deletingUserId,setDeletingUserId] = useState(-1);
  const [openDeleteModal,setOpenDeleteModal] = useState(false);

  const history = useHistory();

  const params = new URLSearchParams(useLocation().search);
  const currentUrlPage = params.get("page");
  const currentPage = currentUrlPage && parseInt(currentUrlPage) ? parseInt(currentUrlPage) : 1;
  const [pageCount, setPageCount] = useState(1)

  const [openPolicy, setOpenPolicy] = useState(false);
  const [workingUser, setWorkingUser] = useState(null);
  const [workingUserPolicyCode, setWorkingUserPolicyCode] = useState(null);

  const getUsers = useCallback(async () => {
    let limit = PAGE_RECORDS;
    let offset = (currentPage - 1) * PAGE_RECORDS;
    setLoading(true);
    hexUsersApi
      .getUsers(limit, offset)
      .execute()
      .then((result) => {
        if (!result.data.hasError) {
          setUsers(result.data.payload.users);
          const totalCount = result.data.payload.totalCount;
          if (totalCount) {
            if (totalCount > PAGE_RECORDS) {
              setPageCount(Math.ceil(totalCount / PAGE_RECORDS))
            }
          }
        }
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [currentPage]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const onPageChanged = (page) => {
    history.push(`${MOBILE_USER_ROUTES.ROOT}?page=${page}`);
  }

  const openPolicyModal = (sender, policyCode) => {
    setWorkingUser(sender);
    setWorkingUserPolicyCode(policyCode);
    setOpenPolicy(true);
  }

  const openShiftHoursModal = (sender) => {
    setCurrentUpdatingUser(sender);
    setOpenShiftHours(true);
  }

  const onShiftHoursUpdated = (senderId, newData) => {
    const ind = users.findIndex(u => u.id === senderId);
    const tempUsers = [...users];
    tempUsers[ind].shiftHours = newData;
    setUsers([...tempUsers]);
    setOpenShiftHours(false);
  }

  const onUserPolicyChanged = (userId, policy) => {
    const ind = users.findIndex(u => u.id === userId);
    const tempUsers = [...users];
    tempUsers[ind].blockedPolicyCode = policy;
    setUsers([...tempUsers]);
  }

  const onEnrollClicked = () => {
    history.push(MOBILE_USER_ROUTES.ENROLL);
  }

  const onDeleteSuccess = (userId)=>{
    const tempUsers = [...users]
    const currentUser = tempUsers.findIndex(u=>u.id === userId);
    tempUsers.splice(currentUser,1);
    setUsers([...tempUsers]);
    toast.success("User Deleted Sucessfully");
  }

  const onDeleteClicked = (sender)=>{
    setDeletingUserId(sender);
    setOpenDeleteModal(true);
  }

  return (
    <>
      <div className="management-c-nav-actions">
        <button
          className="btn btn-default dropdown-toggle"
          type="button"
          onClick={onEnrollClicked}
        >
          Add
        </button>
      </div>
      <div className="management-c-body">
        <div className="df-table-wrapper">
          {loading ? (
            <div>Loading...</div>
          ) : (
            <>
              <table className="table table-responsive ">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Full Name</th>
                    <th>Email Address</th>
                    <th>Mobile Number</th>
                    <th>Type</th>
                    <th>Phone</th>
                    <th>Foreground App</th>
                    <th>Status</th>
                    <th>Blocked</th>
                    <th>Block Policy</th>
                    <th>Compliant</th>
                    <th>Vehicle</th>
                    <th>Has CarPlay</th>
                    <th>Car Mode App</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((u) => (
                    <tr key={u.id}>
                      <td>{u.id}</td>
                      <td>{u.fullName}</td>
                      <td>{u.email}</td>
                      <td>{u.mobileNumber}</td>
                      <td>{u.type}</td>
                      <td>{u.phoneOS}</td>
                      <td>{u.foregroundApp ? u.foregroundApp : "---"}</td>
                      <td>{u.status}</td>
                      <td>{u.blocked ? "Blocked" : "---"}</td>
                      <td>{!!u.blockedPolicyCode ? u.blockedPolicyCode : "Default"}</td>
                      <td>{u.released ? "True" : "False"}</td>
                      <td>{u.vehicleId ? <Link
                        to={`/panel/manage/vehicles/${u.vehicleId}`}
                        className="btn btn-info"
                      >
                        {u.licensePlate}
                      </Link> : "---"}</td>
                      <td>{u.vehicleId ? (u.hasCarPlay ? "Yes" : "No") : "---"}</td>
                      <td>{u.status === "INACTIVE" ? "---" : (u.carModeApp ? "Yes" : "No")}</td>
                      <td>
                        <ActionsDropDown>
                          <ActionItem onClick={() => { openPolicyModal(u.id, u.blockedPolicyCode) }}>Edit Policy</ActionItem>
                          <ActionItem onClick={() => { openShiftHoursModal(u) }}>Edit Shift Hours</ActionItem>
                          {u.type === "BYOD" && (
                          <>
                          <ActionItem onClick={() => { console.log("Clicked") }}>
                            <Link
                              to={`/panel/manage/mobile-users/${u.id}/update`}
                              className="lnk">
                              Edit
                            </Link>
                          </ActionItem>
                          <ActionItem onClick={() => { onDeleteClicked(u.id) }}>Delete</ActionItem>
                        </>)}
                        </ActionsDropDown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {
                (pageCount > 1) && <Pagination currentPage={currentPage} pageCount={pageCount} onPageChanged={onPageChanged} />
              }
            </>
          )}
        </div>
      </div>
      {
        openPolicy && <PolicyModal
          opened={openPolicy}
          setOpened={setOpenPolicy}
          hexUserId={workingUser}
          currentPolicy={workingUserPolicyCode}
          onUpdated={onUserPolicyChanged} />
      }
      {
        openShiftHours && <ShiftHoursModal
          opened={openShiftHours}
          setOpened={setOpenShiftHours}
          oldData={currentUpdatingUser}
          onUpdate={onShiftHoursUpdated} />
      }
      {
                  openDeleteModal && <DeleteModal 
                  opened={openDeleteModal}
                  setOpened={setOpenDeleteModal}
                  userId={deletingUserId}
                  onSuccess={onDeleteSuccess}
                  targetEntity="mobile user"
                  apiDeleteFn={hexUsersApi.deleteCOUUser}/>
                }
    </>
  );
};

export default UserList;
