import React, { useEffect, useState, useCallback } from "react";
import "./index.scss";

import ShiftItem from "./ShiftItem";
import { settingApi } from "api";
import ReminderItem from "./RemindItem";

const defaultData = [
  {
    day: "Mon",
    from: {
      hour: "",
      minute: "",
    },
    to: {
      hour: "",
      minute: "",
    },
  },
  {
    day: "Tue",
    from: {
      hour: "",
      minute: "",
    },
    to: {
      hour: "",
      minute: "",
    },
  },
  {
    day: "Wed",
    from: {
      hour: "",
      minute: "",
    },
    to: {
      hour: "",
      minute: "",
    },
  },
  {
    day: "Thu",
    from: {
      hour: "",
      minute: "",
    },
    to: {
      hour: "",
      minute: "",
    },
  },
  {
    day: "Fri",
    from: {
      hour: "",
      minute: "",
    },
    to: {
      hour: "",
      minute: "",
    },
  },
  {
    day: "Sat",
    from: {
      hour: "",
      minute: "",
    },
    to: {
      hour: "",
      minute: "",
    },
  },
  {
    day: "Sun",
    from: {
      hour: "",
      minute: "",
    },
    to: {
      hour: "",
      minute: "",
    },
  },
];

const ShiftHoursSetting = () => {
  const [data, setData] = useState([]);
  const [remindIOS, setRemindIOS] = useState("No");
  const [loading, setLoading] = useState(false);
  const [apiMessage, setApiMessage] = useState("");

  const manipulateIncomingData = (iData) => {
    try {
      let tempList = [...defaultData];
      iData.forEach((i) => {
        const ind = tempList.findIndex((t) => t.day === i.day);
        tempList[ind] = {
          day: i.day,
          from: {
            hour: i.from.split(":")[0],
            minute: i.from.split(":")[1],
          },
          to: {
            hour: i.to.split(":")[0],
            minute: i.to.split(":")[1],
          },
        };
      });
      setData([...tempList]);
    } catch {
      setData([...defaultData]);
    }
  };

  const getCurrentShiftHours = useCallback(() => {
    setLoading(true);
    settingApi
      .getShiftHours()
      .execute()
      .then((result) => {
        console.log(result);
        if (!result.data.hasError) {
          manipulateIncomingData(result.data.payload.value);
        } else {
          setData([...defaultData]);
        }
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getCurrentShiftHours();
  }, [getCurrentShiftHours]);

  const onShiftItemChanged = (d) => {
    const tempData = [...data];
    const target = tempData.findIndex((t) => t.day === d.day);
    tempData[target] = {
      ...d,
    };
    setData([...tempData]);
  };

  useEffect(() => {
    console.log(data);
  }, [data]);

  const onSaveClicked = () => {
    const finalData = data.map((d) => {
      return {
        day: d.day,
        from: `${d.from.hour}:${d.from.minute}`,
        to: `${d.to.hour}:${d.to.minute}`,
      };
    });
    const filteredItems = [];
    finalData.forEach((fd) => {
      if (fd.from.length > 1) {
        filteredItems.push(fd);
      }
    });
    console.log(filteredItems);
    setLoading(true);
    setApiMessage("");
    settingApi
      .saveShiftHours(filteredItems,remindIOS)
      .execute()
      .then((result) => {
        console.log(result);
        if (result.data.hasError) {
          setApiMessage(result.data.errorMessage || "API Error");
        } else {
          setApiMessage("Shift hours updated");
        }
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="hours-context">
      {apiMessage && <div className="hours-message">{apiMessage}</div>}
      <ul className="shift-items">
        {data.map((d) => (
          <li key={d.day}>
            <ShiftItem data={d} onChange={onShiftItemChanged} />
          </li>
        ))}
      </ul>
        <ReminderItem remindIOS={remindIOS} setRemindIOS={setRemindIOS} loading={loading} setLoading={setLoading}/>
      <div>
        <button
          type="button"
          className="btn btn-default"
          onClick={onSaveClicked}
          disabled={loading}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default ShiftHoursSetting;
