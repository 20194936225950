import axios from "axios";

import cacheService from "./cache-service";
import { HTTP_STATUS_CODES } from "constants/httpStatusCodes";
import mainApiCreator from "./main-api";
import authApiCreator from "./auth-api";
import adminHexUsersApiCreator from "./hex-user-api";
import settingApiCreator from "./setting-api";
import vehicleApiCreator from "./vehicle-api";
import statusLogsApiCreator from "./status-logs-api";
import foregroundAppApiCreator from "./foreground-apps-api";
const CacheService = cacheService();

const AxiosCancelToken = axios.CancelToken;

const transformResponse = (response) => {
  let parsed = JSON.parse(response);
  return parsed;
};

const responseInterceptors = {
  successInterceptor(response) {
    if (response.config.customCache) {
      CacheService.setItem(response.config.cacheKey, response);
    }
    return response;
  },

  errorInterceptor(error) {
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    }
    const statusCode = error?.response?.status;
    if (statusCode) {
      switch (statusCode) {
        case HTTP_STATUS_CODES.CACHED_RESPONSE:
          return Promise.resolve(error.response.data);
        case HTTP_STATUS_CODES.VALIDATION_ERROR:
          // notification.error({
          //   message: error.response?.data?.message,
          // });
          break;
        case HTTP_STATUS_CODES.SERVER_ERROR:
          // notification.error({
          //   message: error.response?.data?.message,
          // });
          break;
        default:
          return error.response;
      }
      return error.response;
    }
    return Promise.reject(error);
  },
};

const requestInterceptors = {
  beforeSent(config) {
    return CacheService.handleCached(config);
  },
  onError(error) {
    return Promise.reject(error);
  },
};

const interceptorHandler = (axiosInstance) => {
  axiosInstance.interceptors.response.use(
    responseInterceptors.successInterceptor,
    responseInterceptors.errorInterceptor
  );
  axiosInstance.interceptors.request.use(
    requestInterceptors.beforeSent,
    requestInterceptors.onError
  );
};

const baseAxiosConfig = {
  transformResponse: transformResponse,
  headers: {
    "Content-Type": "application/json",
  },
};

function apiCaller(axiosInstance, options) {
  let cancelToken = {};
  const execute = () =>
    axiosInstance({
      ...options,
      cancelToken: new AxiosCancelToken((c) => {
        cancelToken.cancel = c;
      }),
    });
  return {
    execute,
    cancelToken,
  };
}

const mainApi = mainApiCreator(
  axios,
  {
    ...baseAxiosConfig,
    baseURL: process.env.REACT_APP_BASE_API_ADDRESS,
  },
  interceptorHandler,
  apiCaller
);

const authApi = authApiCreator(
  axios,
  {
    ...baseAxiosConfig,
    baseURL: `${process.env.REACT_APP_BASE_API_ADDRESS}/users`,
  },
  interceptorHandler,
  apiCaller
);

const hexUsersApi = adminHexUsersApiCreator(
  axios,
  {
    ...baseAxiosConfig,
    baseURL: `${process.env.REACT_APP_BASE_API_ADDRESS}/admin-hex-users`,
  },
  interceptorHandler,
  apiCaller
);

const settingApi = settingApiCreator(
  axios,
  {
    ...baseAxiosConfig,
    baseURL: `${process.env.REACT_APP_BASE_API_ADDRESS}/setting`,
  },
  interceptorHandler,
  apiCaller
);

const vehicleApi = vehicleApiCreator(
  axios,
  {
    ...baseAxiosConfig,
    baseURL: `${process.env.REACT_APP_BASE_API_ADDRESS}/vehicles`,
  },
  interceptorHandler,
  apiCaller
);

const statusLogsApi = statusLogsApiCreator(
  axios,
  {
    ...baseAxiosConfig,
    baseURL: `${process.env.REACT_APP_BASE_API_ADDRESS}/status-logs`,
  },
  interceptorHandler,
  apiCaller
);

const foregroundAppApi = foregroundAppApiCreator(
  axios,
  {
    ...baseAxiosConfig,
    baseURL: `${process.env.REACT_APP_BASE_API_ADDRESS}/foreground-apps`,
  },
  interceptorHandler,
  apiCaller
);

export { HTTP_STATUS_CODES, mainApi, authApi, settingApi, vehicleApi, hexUsersApi, statusLogsApi, foregroundAppApi};
