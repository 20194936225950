import * as Yup from "yup";

export const getCreateBatteryLevelSchema = () => {
  return Yup.object({
    maxLevel: Yup.number()
      .required("Required")
      .min(0, "Please enter a value between 0 and 100")
      .max(100, "Please enter a value between 0 and 100"),
    hint: Yup.string().required("Required"),
    color: Yup.string().required("Required"),
  });
};
