import React from "react";
import Routes from "./Routes";

const Container = () => {
  return (
    <div className="main-container">
      <Routes />
    </div>
  );
};
export default Container;
