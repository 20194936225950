import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { authApi } from "api";
import {
  useHistory
} from "react-router-dom";
export const GlobalContext = createContext({});

export const GlobalProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const globalRoutes = [
    "/auth/reset-password",
    "/auth/forgot-password"
  ]

  const getProfile = useCallback(async () => {
    
    const access_token = localStorage.getItem("access_token");
    if (access_token) {
      //get profile and set user
      const result = await authApi.getProfile(access_token).execute();
      if (!result.data.hasError) {
        const {notificationSettings} = result.data.payload;
        const uData = {
          ...result.data.payload,
          notificationSettings:notificationSettings || {
            dailyEmails:true,
            weeklyEmails:true,
            monthlyEmails:true
          }
        }
        setUser(uData);
        setLoading(false);
      } else {
        history.replace("/");
        localStorage.removeItem("access_token");
        setLoading(false);
        setUser(null);
      }
    } else {
      setLoading(false);
      if(!globalRoutes.includes(history.location.pathname)){
        history.replace("/");
      }
      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getProfile();
  }, [getProfile]);

  return (
    <GlobalContext.Provider value={{ user, setUser, loading }}>
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobal = () => {
  const global = useContext(GlobalContext);
  if (!global) {
    throw new Error("useGlobal should be inside GlobalProvider");
  }
  return global;
};
