import React, { useEffect, useCallback, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { USER_MANAGEMENT_ROUTES } from "constants/routes";
import { PAGE_RECORDS } from 'constants/pagination';
import Pagination from "components/Pagination";
import { authApi } from "api";
import {useGlobal} from "globalContext";
import Options from "./Options";
import LockModal from "./LockModal";
import DeleteModal from "components/DeleteModal";
import { toast } from "shared/utils/toast";

const UserList = () => {
  const history = useHistory();
  const {user} = useGlobal();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const params = new URLSearchParams(useLocation().search);
  const currentUrlPage = params.get("page");
  const currentPage = currentUrlPage && parseInt(currentUrlPage) ? parseInt(currentUrlPage) : 1;
  const [pageCount, setPageCount] = useState(1)
  const [openLockModal,setOpenLockModal] = useState(false);
  const [openDeleteModal,setOpenDeleteModal] = useState(false);
  const [lockingUserId,setLockingUserId] = useState(-1);
  const [deletingUserId,setDeletingUserId] = useState(-1);
  const [currentUserLocked,setCurrentUserLocked] = useState(false);
  const onEnrollClicked = () => {
    history.push(USER_MANAGEMENT_ROUTES.ENROLL);
  };

  const getUsers = useCallback(async () => {
    let limit = PAGE_RECORDS;
    let offset = (currentPage - 1) * PAGE_RECORDS;
    setLoading(true);
    authApi
      .getUsers(limit,offset)
      .execute()
      .then((result) => {
        if (!result.data.hasError) {
          setUsers(result.data.payload.users);
          const totalCount = result.data.payload.totalCount;
          if(totalCount){
            if(totalCount > PAGE_RECORDS){
              setPageCount(Math.ceil(totalCount / PAGE_RECORDS))
            }
          }
        }
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [currentPage]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const getRoles = (roles) => {
    const items = roles.map(r => {
      return r.role
    });
    return items.join(", ");
  }
  const onPageChanged = (page) => {
    history.push(`${USER_MANAGEMENT_ROUTES.ROOT}?page=${page}`);
  }

  const getPrettyDate = (d) => {
    return (new Date(d)).toDateString()
  }

  const onLocked = (userId)=>{
    console.log(userId);
    const tempUsers = [...users]
    const currentUser = tempUsers.findIndex(u=>u.id === userId);
    console.log(currentUser);
    tempUsers[currentUser] = {
      ...tempUsers[currentUser],
      isActive:!tempUsers[currentUser].isActive
    };
    setUsers([...tempUsers]);
  }

  const onDeleteSuccess = (userId)=>{
    const tempUsers = [...users]
    const currentUser = tempUsers.findIndex(u=>u.id === userId);
    tempUsers.splice(currentUser,1);
    setUsers([...tempUsers]);
    toast.success("User Deleted Sucessfully");
  }

  const onLockCliced = (sender,locked)=>{
    setLockingUserId(sender);
    setCurrentUserLocked(locked);
    setOpenLockModal(true);
  }

  const onDeleteClicked = (sender)=>{
    setDeletingUserId(sender);
    setOpenDeleteModal(true);
  }

  
  const getOptions = (targetRoles,targetUserId,locked)=>{
    const roles = getRoles(targetRoles);
    if(roles.includes("INSTALLER")){
      if(roles.includes("ADMIN")){
        if(user.roles.includes("SUPER_ADMIN")){
          return <Options targetUserId={targetUserId} 
            locked={locked}
            onLockClicked={()=>{onLockCliced(targetUserId,locked)}}
            onDeleteClicked={()=>{onDeleteClicked(targetUserId)}}/>;
        }
      }else{
        return <Options targetUserId={targetUserId}
        locked={locked}
            onLockClicked={()=>{onLockCliced(targetUserId,locked)}}
            onDeleteClicked={()=>{onDeleteClicked(targetUserId)}}/>;
      }
      
    }
    if(roles.includes("ADMIN")){
      if(user.roles.includes("SUPER_ADMIN")){
        return <Options targetUserId={targetUserId}
        locked={locked}
            onLockClicked={()=>{onLockCliced(targetUserId,locked)}}
            onDeleteClicked={()=>{onDeleteClicked(targetUserId)}}/>;
      }
    }
    return null;
  }

  

  return (
    <>
      <div className="management-c-nav-actions">
        <button
          className="btn btn-default dropdown-toggle"
          type="button"
          onClick={onEnrollClicked}
        >
          Add
        </button>
      </div>
      <div className="management-c-body">
        <div className="df-table-wrapper">
          {loading ? (
            <div>Loading...</div>
          ) : (
              <>
                <table className="table table-responsive ">
                  <thead>
                    <tr>
                      <th>Full Name</th>
                      <th>Type</th>
                      <th>Email Address</th>
                      <th>Mobile Number</th>
                      <th>Status</th>
                      <th>Creation Date</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((u) => (
                      <tr key={u.id}>
                        <td>{u.fullName}</td>
                        <td>{getRoles(u.roles)}</td>
                        <td>{u.email}</td>
                        <td>{u.mobileNumber}</td>
                        <td>{u.isActive ? "Active" : "Blocked"}</td>
                        <td>{getPrettyDate(u.createdAt)}</td>
                        <td>{getOptions(u.roles,u.id,!u.isActive)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {
                  (pageCount > 1) && <Pagination currentPage={currentPage} pageCount={pageCount} onPageChanged={onPageChanged} />
                }
                {
                  openLockModal && <LockModal 
                    opened={openLockModal}
                    setOpened={setOpenLockModal}
                    userId={lockingUserId}
                    onLocked={onLocked}
                    currentUserLocked={currentUserLocked}/>
                }
                {
                  openDeleteModal && <DeleteModal 
                  opened={openDeleteModal}
                  setOpened={setOpenDeleteModal}
                  userId={deletingUserId}
                  onSuccess={onDeleteSuccess}
                  targetEntity="user"
                  apiDeleteFn={authApi.deleteUser}/>
                }
              </>
            )}
        </div>
      </div>
    </>
  );
};

export default UserList;
