import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { ACCOUNT_ROUTES } from "constants/routes";
import Profile from "./Profile";
import ChangePassword from "./ChangePassword";
const Account = () => {
  return (
    <Switch>
      <Route path={ACCOUNT_ROUTES.EDIPT_PROFILE} component={Profile} />
      <Route path={ACCOUNT_ROUTES.CHANGE_PASSWORD} component={ChangePassword} />
      <Redirect from="/" to={ACCOUNT_ROUTES.EDIPT_PROFILE} />
    </Switch>
  );
};

export default Account;
