import React, { useState, useEffect } from "react";

const TimePicker = ({ data, onChange }) => {
  const [hour, setHour] = useState(data.hour);
  const [minute, setMinute] = useState(data.minute);

  useEffect(() => {
    if (!!hour && !!minute) {
      if (onChange) {
        onChange(hour, minute);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hour, minute]);

  const onHourChanged = (e) => {
    setHour(e.target.value);
  };
  const onMinuteChanged = (e) => {
    setMinute(e.target.value);
  };
  return (
    <div className="time-picker">
      <span>
        <input
          type="number"
          min="0"
          max="23"
          value={hour}
          onChange={onHourChanged}
        />
      </span>
      <span>:</span>
      <span>
        <input
          type="number"
          min="0"
          max="59"
          value={minute}
          onChange={onMinuteChanged}
        />
      </span>
    </div>
  );
};

export default TimePicker;
