import React, { useState, useEffect, useCallback } from "react";

import { useHistory, useParams } from "react-router-dom";
import { USER_MANAGEMENT_ROUTES } from "constants/routes";

import { useFormik } from "formik";
import { getChangePasswordValidationSchema } from "./validationSchema";
import { authApi, HTTP_STATUS_CODES } from "api";
import { USER_ROLES } from "constants/userRoles";
import { useGlobal } from "globalContext";

const NewUser = () => {
  const history = useHistory();
  const { id } = useParams();
  const { user } = useGlobal()
  const [loading, setLoading] = useState(false);
  const [apiErrors, setApiErros] = useState([]);
  const [apiMessage, setApiMessage] = useState("");
  const [initialValues, setInitialValues] = useState({
    fullName: "",
    email: "",
    mobileNumber: "",
    password: "",
  });
  const roles = [
    {
      id: 2,
      text: "Admin",
    },
    {
      id: 3,
      text: "Installer",
    },
  ];



  const [selectedRoles, setSelectedRoles] = useState({
    [roles[0].id]: false,
    [roles[1].id]: true,
  });

  const checkAccess = (targetUser) => {
    const roles = [...targetUser.roles];
    if (user.id === targetUser.id) {
      history.replace(USER_MANAGEMENT_ROUTES.ROOT);
    }
    if (roles.includes("INSTALLER")) {
      if (roles.includes("ADMIN")) {
        if (!user.roles.includes("SUPER_ADMIN")) {
          history.replace(USER_MANAGEMENT_ROUTES.ROOT);
        } else {
          initialize(targetUser);
        }
      } else {
        initialize(targetUser);
      }
    } else if (roles.includes("ADMIN")) {
      if (!user.roles.includes("SUPER_ADMIN")) {
        history.replace(USER_MANAGEMENT_ROUTES.ROOT);
      } else {
        initialize(targetUser);
      }
    } else if (roles.includes("SUPER_ADMIN")) {
      history.replace(USER_MANAGEMENT_ROUTES.ROOT);
    } else {
      initialize(targetUser);
    }
  }

  const initialize = (targetUser) => {
    setInitialValues({
      fullName: targetUser.fullName,
      mobileNumber: targetUser.mobileNumber,
      email: targetUser.email,
      password: "not_changed"
    });
    setSelectedRoles({
      [roles[0].id]: !!targetUser.roles.includes(USER_ROLES.ADMIN),
      [roles[1].id]: !!targetUser.roles.includes(USER_ROLES.INSTALLER),
    })
  }

  const getUserDetails = useCallback(() => {
    setLoading(true);
    authApi
      .getUserByAdmin(id)
      .execute()
      .then((result) => {
        console.log(result);
        if (result.data.hasError) {
          history.replace(USER_MANAGEMENT_ROUTES.ROOT);
        } else {
          const payload = result.data.payload;
          checkAccess(payload);
        }
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id) {
      getUserDetails();
    }
  }, [id, getUserDetails]);

  const onBackClicked = () => {
    history.push(USER_MANAGEMENT_ROUTES.ROOT);
  };

  const saveUser = (apiFn, data) => {
    const roleIds = Object.keys(selectedRoles).filter(
      (sr) => selectedRoles[sr]
    );
    const numericRoleIds = roleIds.map((ri) => parseInt(ri));
    authApi
    [apiFn]({ ...data, roleIds: numericRoleIds })
      .execute()
      .then((result) => {
        if (result.data.hasError) {
          if (
            result.data.statusCode === HTTP_STATUS_CODES.VALIDATION_ERROR
          ) {
            let validationErrors = [];
            Object.keys(result.data.errors).forEach((er) => {
              validationErrors = [
                ...validationErrors,
                ...result.data.errors[er],
              ];
            });
            setApiErros([...validationErrors]);
          } else {
            setApiErros([result.data.message]);
          }
        } else {
          if (id) {
            history.push(USER_MANAGEMENT_ROUTES.ROOT);
          } else {
            setApiMessage("User created successfully");
            formik.resetForm();
            setSelectedRoles({
              [roles[0].id]: false,
              [roles[1].id]: true,
            });;
          }

        }
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: getChangePasswordValidationSchema(),
    onSubmit: (values) => {
      const newValues = { ...values };
      if (!loading) {
        setApiErros([]);
        setLoading(true);
        if (id && newValues.password === "not_changed") {
          newValues.password = "";
        }
        saveUser(
          id ? "updateUserByAdmin" : "createUserByAdmin",
          id
            ? {
              ...newValues,
              id: id,
            }
            : {
              ...values,
            }
        );
      }
    },
  });

  const onRoleClicked = (sender) => {
    const tempSelectedRoles = { ...selectedRoles };
    tempSelectedRoles[sender.id] = !tempSelectedRoles[sender.id];
    const activeItems = Object.keys(tempSelectedRoles).filter(
      (tk) => tempSelectedRoles[tk]
    );
    if (activeItems.length) {
      setSelectedRoles(tempSelectedRoles);
    }
  };

  return (
    <>
      <div className="management-c-nav-actions">
        <button
          className="btn btn-default dropdown-toggle"
          type="button"
          onClick={onBackClicked}
        >
          Back to list
        </button>
      </div>
      <div className="management-c-body">
        <div className="df-box">
          <h3 className="box-title">{id ? "Update User" : "Create new User"} </h3>
          <div className="box-body">
            {!!apiErrors.length && (
              <div>
                <ul className="form-errors">
                  {apiErrors.map((ae) => (
                    <li key={ae}>{ae}</li>
                  ))}
                </ul>
              </div>
            )}
            {!!apiMessage && (
              <div className="form-success-message">{apiMessage}</div>
            )}
            <form onSubmit={formik.handleSubmit}>
              <div
                className={`form-group ${formik.touched.fullName && formik.errors.fullName
                    ? "has-error"
                    : ""
                  }`}
              >
                <label htmlFor="currentPassword">Full Name</label>
                <input
                  type="text"
                  id="fullName"
                  name="fullName"
                  className="form-control"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fullName}
                />
                {formik.touched.fullName && formik.errors.fullName && (
                  <span className="help-block">{formik.errors.fullName}</span>
                )}
              </div>
              <div
                className={`form-group ${formik.touched.email && formik.errors.email ? "has-error" : ""
                  }`}
              >
                <label htmlFor="currentPassword">Email Address</label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  className="form-control"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email && (
                  <span className="help-block">{formik.errors.email}</span>
                )}
              </div>
              <div
                className={`form-group ${formik.touched.mobileNumber && formik.errors.mobileNumber
                    ? "has-error"
                    : ""
                  }`}
              >
                <label htmlFor="currentPassword">Mobile Number</label>
                <input
                  type="text"
                  id="mobileNumber"
                  name="mobileNumber"
                  className="form-control"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.mobileNumber}
                />
                {formik.touched.mobileNumber && formik.errors.mobileNumber && (
                  <span className="help-block">
                    {formik.errors.mobileNumber}
                  </span>
                )}
              </div>
              <div
                className={`form-group ${formik.touched.password && formik.errors.password
                    ? "has-error"
                    : ""
                  }`}
              >
                <label htmlFor="currentPassword">Password</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  className="form-control"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                />
                {formik.touched.password && formik.errors.password && (
                  <span className="help-block">{formik.errors.password}</span>
                )}
              </div>

              <div className="form-group">
                <label>Roles</label>
                <ul className="df-multiple-options">
                  {roles.map((r) => (
                    <li
                      key={r.id}
                      onClick={() => {
                        onRoleClicked(r);
                      }}
                      className={`${selectedRoles[r.id] ? "df-selected-option" : ""
                        }`}
                    >
                      {r.text}
                    </li>
                  ))}
                </ul>
              </div>

              <div className="box-actions">
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={loading}
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewUser;
