import { authApi } from "api";
import React, { useEffect, useState } from "react";
import "./styles.scss";

export const NotificationSettings = ({data}) => {
    const [loading,setLoading] = useState(false);
    const [apiMessage,setApiMessage] = useState("");
    const [notificationSetting,setNotificationSetting] = useState(data || {
        dailyEmails:false,
        weeklyEmails:false,
        monthlyEmails:false
    });

    useEffect(()=>{
        setNotificationSetting(data);
    },[data])


    const onItemChange = (e)=>{
        setNotificationSetting({
            ...notificationSetting,
            [e.target.name]:e.target.checked
        })
    }


    const onSaveClicked = ()=>{
        setLoading(true);
        authApi.updateNotificationSettings(notificationSetting)
            .execute()
            .then((result)=>{
                console.log(result);
                setApiMessage(result.data.message);
            })
            .finally(()=>{
                setLoading(false);
            })
    }

    return <div className="df-box notification-settings">
        <h3 className="box-title">Safety Alert Thresholds</h3>
        <div className="box-body">
        {!!apiMessage && (
              <div className="form-success-message">{apiMessage}</div>
            )}
            <div className="setting-item">
                <label>
                    <input type="checkbox" name="dailyEmails" onChange={onItemChange}
                        checked={notificationSetting.dailyEmails}/>
                    Get Daily Shift Log Emails
                </label>
            </div>
            <div className="setting-item">
                <label>
                    <input type="checkbox" name="weeklyEmails" onChange={onItemChange}
                        checked={notificationSetting.weeklyEmails}/>
                    Get Weekly Shift Log Emails
                </label>
            </div>
            <div className="setting-item">
                <label>
                    <input type="checkbox" name="monthlyEmails" onChange={onItemChange}
                    checked={notificationSetting.monthlyEmails}/>
                    Get Monthly Shift Log Emails
                </label>
            </div>
            <div className="box-actions">
                <button
                    className="btn btn-primary"
                    type="button"
                    onClick={onSaveClicked}
                    disabled={loading}
                >
                    Save
                </button>
            </div>
        </div>
    </div>
}