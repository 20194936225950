import React, { useState, useEffect, useCallback } from "react";

import { useHistory, useParams } from "react-router-dom";
import { VEHICLE_MANAGEMENT_ROUTES } from "constants/routes";

import { useFormik } from "formik";
import { getNewVehicleValidationSchema } from "./validationSchema";
import { vehicleApi, HTTP_STATUS_CODES } from "api";
import ConfirmModal from "components/ConfirmModal";
import {toast} from "shared/utils/toast";

const NewVehicle = () => {
  const history = useHistory();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [apiErrors, setApiErros] = useState([]);
  const [apiMessage, setApiMessage] = useState("");
  const [showConfirmModal,setShowConfirmModal] = useState(false);
  const [updatingVehicleData,setUpdatingVehicleData] = useState(null);
  const [initialValues, setInitialValues] = useState({
    make: "",
    model: "",
    productionYear: 0,
    vehicleName:"",
    maxBeacons:1,
    hasCarPlay: "No"
  });
  // licensePlate: "",
  // vin: "",
  // color: "",

  const onBackClicked = () => {
    history.push(VEHICLE_MANAGEMENT_ROUTES.ROOT);
  };

  const getVehicleDetails = useCallback(() => {
    setLoading(true);
    vehicleApi
      .getVehicleById(id)
      .execute()
      .then((result) => {
        if (result.data.hasError) {
          history.replace(VEHICLE_MANAGEMENT_ROUTES.ROOT);
        } else {
          const payload = result.data.payload;
          setInitialValues({
            make: payload.make,
            model: payload.model,
            productionYear: payload.productionYear,
            vehicleName:payload.vehicleName || "",
            maxBeacons:payload.maxBeacons || 1,
            hasCarPlay:payload.hasCarPlay ? "Yes" : "No"
          });
          /*
          licensePlate: payload.licensePlate,
            vin: payload.vin,
            color: payload.color,
          */
        }
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id) {
      getVehicleDetails();
    }
  }, [id, getVehicleDetails]);

  const saveVehicle = (apiFn, data) => {
    vehicleApi[apiFn]({ ...data })
      .execute()
      .then((result) => {
        if (result.data.hasError) {
          if (result.data.statusCode === HTTP_STATUS_CODES.VALIDATION_ERROR) {
            let validationErrors = [];
            Object.keys(result.data.errors).forEach((er) => {
              validationErrors = [
                ...validationErrors,
                ...result.data.errors[er],
              ];
            });
            setApiErros([...validationErrors]);
          } else {
            setApiErros([result.data.message]);
          }
        } else {
          if (id) {
            toast.success("Vehicle Updated successfully")
            history.push(VEHICLE_MANAGEMENT_ROUTES.ROOT);
          } else {
            setApiMessage("Vehicle created successfully");
            formik.resetForm();
          }
        }
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onUpdateConfirmed = ()=>{
    setLoading(true);
    saveVehicle("editVehicle",updatingVehicleData);
  }

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: getNewVehicleValidationSchema(),
    onSubmit: (values) => {
      if (!loading) {
        setApiErros([]);
        if(id){
          //show modal
          setShowConfirmModal(true);
          setUpdatingVehicleData({
            ...values,
            maxBeacons:parseInt(values.maxBeacons),
            id: id,
          });
        }else{
          setLoading(true);
        saveVehicle(
          "createVehicle",
          {
            ...values,
            maxBeacons:parseInt(values.maxBeacons),
          }
        );
        }
      }
    },
  });

  return (
    <>
      <div className="management-c-nav-actions">
        <button
          className="btn btn-default dropdown-toggle"
          type="button"
          onClick={onBackClicked}
        >
          Back to list
        </button>
      </div>
      <div className="management-c-body">
        <div className="df-box">
          <h3 className="box-title">Create new Vehicle</h3>
          <div className="box-body">
            {!!apiErrors.length && (
              <div>
                <ul className="form-errors">
                  {apiErrors.map((ae) => (
                    <li key={ae}>{ae}</li>
                  ))}
                </ul>
              </div>
            )}
            {!!apiMessage && (
              <div className="form-success-message">{apiMessage}</div>
            )}
            <form onSubmit={formik.handleSubmit}>
            <div
                className={`form-group ${
                  formik.touched.vehicleName && formik.errors.vehicleName ? "has-error" : ""
                }`}
              >
                <label htmlFor="currentPassword">Vehicle Name</label>
                <input
                  type="text"
                  id="vehicleName"
                  name="vehicleName"
                  className="form-control"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.vehicleName}
                />
                {formik.touched.vehicleName && formik.errors.vehicleName && (
                  <span className="help-block">{formik.errors.vehicleName}</span>
                )}
              </div>
              <div
                className={`form-group ${
                  formik.touched.maxBeacons && formik.errors.maxBeacons ? "has-error" : ""
                }`}
              >
                <label htmlFor="currentPassword">Number of beacons</label>
                <input
                  type="text"
                  id="maxBeacons"
                  name="maxBeacons"
                  className="form-control"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.maxBeacons}
                />
                {formik.touched.maxBeacons && formik.errors.maxBeacons && (
                  <span className="help-block">{formik.errors.maxBeacons}</span>
                )}
              </div>
              <div
                className={`form-group ${
                  formik.touched.make && formik.errors.make ? "has-error" : ""
                }`}
              >
                <label htmlFor="currentPassword">Manufacturer</label>
                <input
                  type="text"
                  id="make"
                  name="make"
                  className="form-control"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.make}
                />
                {formik.touched.make && formik.errors.make && (
                  <span className="help-block">{formik.errors.make}</span>
                )}
              </div>
              <div
                className={`form-group ${
                  formik.touched.model && formik.errors.model ? "has-error" : ""
                }`}
              >
                <label htmlFor="currentPassword">Model</label>
                <input
                  type="text"
                  id="model"
                  name="model"
                  className="form-control"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.model}
                />
                {formik.touched.model && formik.errors.model && (
                  <span className="help-block">{formik.errors.model}</span>
                )}
              </div>

              <div
                className={`form-group ${
                  formik.touched.productionYear && formik.errors.productionYear
                    ? "has-error"
                    : ""
                }`}
              >
                <label htmlFor="currentPassword">Production Year</label>
                <input
                  type="text"
                  id="productionYear"
                  name="productionYear"
                  className="form-control"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.productionYear}
                />
                {formik.touched.productionYear &&
                  formik.errors.productionYear && (
                    <span className="help-block">
                      {formik.errors.productionYear}
                    </span>
                  )}
              </div>
              <div
                className={`form-group ${
                  formik.touched.model && formik.errors.model ? "has-error" : ""
                }`}
              >
                <label htmlFor="currentPassword">Has CarPlay</label>
                <div> 
                  <input
                      id="No"
                      type="radio"
                      value="No"
                      name="hasCarPlay"
                      onChange={formik.handleChange}
                      checked={formik.values.hasCarPlay === "No"}
                      style={{marginRight:5}}
                    />
                  <label htmlFor="currentPassword" style={{marginRight:10}}>No</label>
                  <input
                    id="Yes"
                    type="radio"
                    value="Yes"
                    name="hasCarPlay"
                    onChange={formik.handleChange}
                    checked={formik.values.hasCarPlay === "Yes"}
                    style={{marginRight:5}}
                  />
                  <label htmlFor="currentPassword">Yes</label>
                 </div>
                {formik.touched.model && formik.errors.model && (
                  <span className="help-block">{formik.errors.model}</span>
                )}
              </div>


              <div className="box-actions">
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={loading}
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {showConfirmModal && <ConfirmModal onConfirm={onUpdateConfirmed} 
        opened={showConfirmModal}
        title="Are You Sure?"
        description="Some changes may affect functionality(like Beacons count)"
        setOpened={setShowConfirmModal}
        />}
    </>
  );
};

export default NewVehicle;
