import React, { useState, useEffect } from "react";
import "./index.scss";

const Pagination = ({ pageCount, currentPage, onPageChanged }) => {
    const [current, setCurrent] = useState(currentPage);
    const [pages, setPages] = useState([]);
    useEffect(() => {
        const tempPages = [];
        for (let i = 0; i < pageCount; i++) {
            tempPages.push(i+1);
        }
        setPages(tempPages);
    }, [pageCount])

    useEffect(() => {
        onPageChanged(current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current])

    const onPageClicked = (sender) => {
        console.log(sender)
        if(current !== sender){
            setCurrent(sender);
        }
    }

    const onPrev = () => {
        if(current > 1){
            setCurrent(current - 1);
        }
    }

    const onNext = () => {
        if(current < pages.length){
            setCurrent(current + 1);
        }
    }

    return (<div className="df-pagination">
        <ul>
            <li>
                <span className="p-action" onClick={onPrev}>
                    <span className="glyphicon glyphicon-chevron-left" />
                </span>
            </li>
            {
                pages.map(p => (<li key={p}>
                    <span className={`p-item ${current === p?"active-page":""}`}
                        onClick={() => { onPageClicked(p) }}>{p}</span>
                </li>))
            }
            <li>
                <span className="p-action" onClick={onNext}>
                    <span className="glyphicon glyphicon-chevron-right" />
                </span>
            </li>
        </ul>
    </div>);
}

export default Pagination;