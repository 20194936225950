import * as Yup from "yup";

export const getChangePasswordValidationSchema = () => {
  return Yup.object({
    currentPassword: Yup.string().required("Required"),
    newPassword: Yup.string()
      .min(6, "Must be 6 characters or more")
      .required("Required"),
  });
};
