import React, { useState, useRef } from "react";
import { useGlobal } from "globalContext";
import "./index.scss";
import useOutSideClickHandler from "shared/hooks/handlClickOutside";
import { Link, useHistory } from "react-router-dom";
import { ACCOUNT_ROUTES, MAIN_ROUTES } from "constants/routes";

const UserBox = () => {
  const { user, setUser } = useGlobal();
  const userMenuRef = useRef(null);
  const [dropDownOpened, setDropDownOpened] = useState(false);
  const history = useHistory();
  const onUserNameClicked = () => {
    setDropDownOpened((current) => !current);
  };

  useOutSideClickHandler(userMenuRef, () => {
    setDropDownOpened(false);
  });

  const logout = () => {
    localStorage.removeItem("access_token");
    setUser(null);
    history.push(MAIN_ROUTES.ROOT);
  };

  const onLinkItemClick = () => {
    setDropDownOpened(false);
  };

  return (
    <div className="user-box" ref={userMenuRef}>
      <div className="user-name" onClick={onUserNameClicked}>
        <span className="glyphicon glyphicon-user" />
        {user.fullName}
      </div>
      <div className={`user-drop-down ${dropDownOpened ? "open" : ""}`}>
        <ul className="user-nav">
          <li>
            <Link to={ACCOUNT_ROUTES.EDIPT_PROFILE} onClick={onLinkItemClick}>
              Profile
            </Link>
          </li>
          <li>
            <Link to={ACCOUNT_ROUTES.CHANGE_PASSWORD} onClick={onLinkItemClick}>
              Change Password
            </Link>
          </li>
          <li>
            <span onClick={logout}>Logout</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default UserBox;
