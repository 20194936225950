import * as Yup from "yup";

export const getNewVehicleValidationSchema = () => {
  return Yup.object({
    make: Yup.string().required("Required"),
    model: Yup.string().required("Required"),
    productionYear: Yup.number().required("Required"),
    maxBeacons: Yup.number().required("Required")
      .min(1,"Minimum value is 1")
      .max(4,"Maximum value is 4"),
    vehicleName: Yup.string().required("Required")
  });
};
// licensePlate: Yup.string().required("Required"),
//     vin: Yup.string().required("Required"),
//     color: Yup.string().required("Required"),