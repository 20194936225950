import React from "react";
import { Link } from "react-router-dom";

import ActionsDropDown from "components/ActionsDropDown";
import ActionItem from "components/ActionsDropDown/ActionItem";


const Options = ({ targetUserId, onLockClicked, locked,onDeleteClicked }) => {

    return (
        <>
            <ActionsDropDown>
                <ActionItem onClick={() => { }}>
                    <Link
                        to={`/panel/manage/users/${targetUserId}/update`}
                        className="lnk">
                        Edit
                    </Link>
                </ActionItem>
                <ActionItem onClick={() => { onLockClicked(targetUserId) }}>
                        {locked ? "Unlock" : "Lock"}
                </ActionItem>
                <ActionItem onClick={() => { onDeleteClicked(targetUserId) }}>Delete</ActionItem>
            </ActionsDropDown>
        </>);
}

export default Options;