import React from "react";

import { Switch, Route } from "react-router-dom";
import { FOREGROUND_APP_MANAGEMENT_ROUTES } from "constants/routes";

import ForegroundAppList from "./AppList";
import NewForegroundApp from "./NewApp";

const ForegroundAppManagement = () => {
  return (
    <div className="management-container">
      <Switch>
        <Route path={FOREGROUND_APP_MANAGEMENT_ROUTES.NEW} component={NewForegroundApp} />
        <Route path={FOREGROUND_APP_MANAGEMENT_ROUTES.EDIT} component={NewForegroundApp} />
        <Route path={FOREGROUND_APP_MANAGEMENT_ROUTES.ROOT} component={ForegroundAppList} />
      </Switch>
    </div>
  );
};

export default ForegroundAppManagement;
