import * as Yup from "yup";

export const getChangePasswordValidationSchema = () => {
  return Yup.object({
    fullName: Yup.string().required("Required"),
    email: Yup.string()
      .required("Required")
      .email("Must be a valid Email address"),
    mobileNumber: Yup.string().required("Required"),
    password: Yup.string()
      .min(6, "Must be 6 characters or more")
      .required("Required"),
  });
};
