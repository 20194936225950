const adminHexApi = (axios, config, interceptorHandler, apiCaller) => {
    const axiosInstance = axios.create(config);
    interceptorHandler(axiosInstance);
    return {
        getUsers(limit, offset) {
            return apiCaller(axiosInstance, {
                method: "GET",
                url: `/?limit=${limit}&offset=${offset}`,
                headers: {
                    authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            });
        },
        assignPolicy(hexUserId, policyCode) {
            return apiCaller(axiosInstance, {
                method: "PUT",
                url: "/assign-policy",
                headers: {
                    authorization: "Bearer " + localStorage.getItem("access_token"),
                },
                data: {
                    hexUserId,
                    policyCode
                }
            });
        },
        removePolicy(hexUserId) {
            return apiCaller(axiosInstance, {
                method: "PUT",
                url: "/remove-policy",
                headers: {
                    authorization: "Bearer " + localStorage.getItem("access_token"),
                },
                data: {
                    hexUserId
                }
            });
        },
        saveShiftHours(hexUserId, data) {
            return apiCaller(axiosInstance, {
                method: "PUT",
                url: "/shift-hours",
                headers: {
                    authorization: "Bearer " + localStorage.getItem("access_token"),
                },
                data: {
                    hexUserId,
                    shiftHours: data ? data.length ? data : null : null
                }
            });
        },
        getUserByAdmin(userId) {
            return apiCaller(axiosInstance, {
                method: "get",
                url: `/${userId}`,
                headers: {
                    authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            });
        },
        updateUserByAdmin(user) {
            return apiCaller(axiosInstance, {
                method: "PUT",
                url: "/" + user.id,
                headers: {
                    authorization: "Bearer " + localStorage.getItem("access_token"),
                },
                data: user,
            });
        },
        createUserByAdmin(user) {
            return apiCaller(axiosInstance, {
                method: "POST",
                url: "/",
                headers: {
                    authorization: "Bearer " + localStorage.getItem("access_token"),
                },
                data: user,
            });
        },
        deleteCOUUser(id) {
            return apiCaller(axiosInstance, {
                method: "DELETE",
                url: "/" + id,
                headers: {
                    authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            });
        }
    };
};

export default adminHexApi;
