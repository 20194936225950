import React, { useEffect, useCallback, useState } from "react";

import { useHistory, useParams } from "react-router-dom";
import { VEHICLE_MANAGEMENT_ROUTES } from "constants/routes";
import { vehicleApi } from "api";
import Beacons from "./Beacons";
import Occupants from "./Occupants";

const VehicleDetails = () => {
  const history = useHistory();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState(null);
  const [occupants,setOccupants] = useState([]);
  
  const getVOccupants = (id)=>{
    vehicleApi.getOccupants(id)
      .execute()
      .then((r)=>{
        if(!r.data.hasError){
          setOccupants(r.data.payload);
        }
      })
  }

  const getVehicleDetails = useCallback(() => {
    setLoading(true);
    vehicleApi
      .getVehicleById(id)
      .execute()
      .then((result) => {
        console.log(result);
        if (result.data.hasError) {
          history.replace(VEHICLE_MANAGEMENT_ROUTES.ROOT);
        } else {
          const tempBeacons = result.data.payload.beacons || []
          const payload = {
            ...result.data.payload,
            beacons:tempBeacons.map(b=>{
              return {
                ...b,
                vehicleId:id
              }
            })
          };
          setDetails(payload);
          getVOccupants(id);
        }
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id) {
      getVehicleDetails();
    }
  }, [id, getVehicleDetails]);

  const onBackClicked = () => {
    history.push(VEHICLE_MANAGEMENT_ROUTES.ROOT);
  };

  const onBeaconUpdated = (beacon,index)=>{
    //beacon.id
    //beacon.macAddress
    console.log(beacon);
    const beacons = [...details.beacons];
    beacons[index] = {
      ...beacon
    };
    setDetails({
      ...details,
      beacons:[
        ...beacons
      ]
    })
  }

  return (
    <>
      {loading || !details ? (
        <div>Loading</div>
      ) : (
        <div>
          <div className="management-c-nav-actions">
            <button
              className="btn btn-default dropdown-toggle"
              type="button"
              onClick={onBackClicked}
            >
              Back to list of vehicles
            </button>
          </div>
          <div className="management-c-body">
            <div className="details-box">
              <h3 className="section-tite">Details</h3>
              <div className="details-section">
                <div className="details-item">
                  <span>Manufacturer</span>
                  <span>{details.make}</span>
                </div>
                <div className="details-item">
                  <span>Model</span>
                  <span>{details.model}</span>
                </div>
                <div className="details-item">
                  <span>Color</span>
                  <span>{details.color}</span>
                </div>
                <div className="details-item">
                  <span>Production Year</span>
                  <span>{details.productionYear}</span>
                </div>
                <div className="details-item">
                  <span>License Plate</span>
                  <span>{details.licensePlate}</span>
                </div>
                <div className="details-item">
                  <span>VIN</span>
                  <span>{details.vin}</span>
                </div>
                <div className="details-item">
                  <span>Created By</span>
                  <span>{details.createdBy[0].f2}</span>
                </div>
                <div className="details-item">
                  <span>Created At</span>
                  <span>{details.createdAt}</span>
                </div>
                <div className="details-item">
                  <span>Updated By</span>
                  <span>{details.updatedBy[0].f2}</span>
                </div>
                <div className="details-item">
                  <span>Updated At</span>
                  <span>{details.updatedAt}</span>
                </div>
                <div className="details-item">
                  <span>Has CarPlay</span>
                  <span>{details.hasCarPlay? "Yes" : "No"}</span>
                </div>
              </div>
              <h3 className="section-tite">Occupants</h3>
              <div className="details-section">
                <Occupants occupants={occupants}/>
              </div>
              <h3 className="section-tite">Beacons</h3>
              <div className="details-section">
                <Beacons beacons={details.beacons} onBeaconUpdated={onBeaconUpdated}/>
              </div>
              <h3 className="section-tite">Training Signature</h3>
              <div className="details-section">
                <div><pre>{JSON.stringify(details.trainingSignature, null, 2) }</pre></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default VehicleDetails;
