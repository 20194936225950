import { settingApi } from "api";
import React, { useCallback, useEffect, useState } from "react";
import "./notifications.scss";
import { toast } from "shared/utils/toast";

const Notifications = () => {

    const [data, setData] = useState([]);
    const [, setLoading] = useState(false);
    const [editingItem, setEditingItem] = useState(null);
    const [updatingItem,setUpdatingItem] = useState(false);

    const fetchData = useCallback(() => {
        setLoading(false);
        settingApi.getLogNotifications()
            .execute()
            .then((res) => {
                console.log(res);
                if (!res.data.hasError) {
                    setData(res.data.payload);
                }
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const onEditItemClicked = (senderIndex) => {
        setEditingItem(data[senderIndex]);
    }

    const onEditClicked = () => {
        if(editingItem.title && editingItem.limitationValue){
            if(!isNaN(parseInt(editingItem.limitationValue)) && parseInt(editingItem.limitationValue) >= 0){
                setUpdatingItem(true);
                settingApi.updateLogNotification(editingItem.id,
                    editingItem.title,editingItem.limitationValue)
                    .execute()
                    .then((res)=>{
                        if(!res.data.hasError){
                            toast.success("Item Edited successfuly");
                            const targetIndex = data.findIndex(it=>it.id === editingItem.id);
                            const tempData = [...data];
                            tempData[targetIndex].title = editingItem.title;
                            tempData[targetIndex].limitationValue = editingItem.limitationValue;
                            setData([...tempData]);
                            setEditingItem(null);
                        }else{
                            toast.error("There is a problem when updating the item");
                        }
                    })
                    .catch((err)=>{
                        console.log(err);
                    })
                    .finally(()=>{
                        setUpdatingItem(false);
                    })
            }
        }
    }

    const onCancelEditClicked = () => {
        setEditingItem(null);
    }

    const onTitleChanged = (e) => {
        setEditingItem({
            ...editingItem,
            title: e.target.value
        });
    }

    const onLimitationChanged = (e) => {
        setEditingItem({
            ...editingItem,
            limitationValue: e.target.value
        });
    }

    return <div className="management-c-body notifications-data">
        <div className="df-table-wrapper">
            <table className="table table-responsive ">
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Value</th>
                        <th>Value Type</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((item, index) => (
                            <tr key={item.id}>
                                <td>{item.title}</td>
                                <td>{item.limitationValue}</td>
                                <td>{item.limitationValueUnit}</td>
                                <td>
                                    <button
                                        className="btn btn-primary"
                                        type="button"
                                        onClick={() => { onEditItemClicked(index) }}
                                        disabled={updatingItem}
                                    >
                                        Edit
                                    </button>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
        {
            editingItem && (
                <div className="edit-form">
                    <h3 className="e-title">Edit Item</h3>
                    <div className="e-form-body">
                        <div>
                            <label>Title</label>
                            <input type="text" value={editingItem.title} onChange={onTitleChanged} />
                        </div>
                        <div>
                            <label>Value</label>
                            <input type="number" value={editingItem.limitationValue} onChange={onLimitationChanged}
                                min="0" max="100" />
                        </div>
                        <div>
                            <button type="button" onClick={onEditClicked}
                                className="btn btn-primary" disabled={updatingItem}>Save</button>
                            <button type="button" onClick={onCancelEditClicked}
                                className="btn btn-info">Cancel</button>
                        </div>
                    </div>
                </div>
            )
        }
    </div>
}

export default Notifications;