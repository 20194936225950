import React, { useState } from "react";
import { useFormik } from "formik";
import { getChangePasswordValidationSchema } from "./validationSchema";
import { authApi } from "api";

const ChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const [apiErrors, setApiErros] = useState([]);
  const [apiMessage, setApiMessage] = useState("");

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
    },
    validationSchema: getChangePasswordValidationSchema(),
    onSubmit: (values) => {
      if (!loading) {
        setApiErros([]);
        setLoading(true);
        authApi
          .changePassword(values)
          .execute()
          .then((result) => {
            if (result.data.hasError) {
              setApiErros([result.data.message]);
            } else {
              setApiMessage(result.data.message);
              formik.resetForm();
            }
          })
          .finally(() => {
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  });
  return (
    <div className="df-box change-password-box">
      <h3 className="box-title">Change Password</h3>
      <div className="box-body">
        {!!apiErrors.length && (
          <div>
            <ul className="form-errors">
              {apiErrors.map((ae) => (
                <li key={ae}>{ae}</li>
              ))}
            </ul>
          </div>
        )}
        {!!apiMessage && (
          <div className="form-success-message">{apiMessage}</div>
        )}
        <form onSubmit={formik.handleSubmit}>
          <div
            className={`form-group ${
              formik.touched && formik.errors.currentPassword ? "has-error" : ""
            }`}
          >
            <label htmlFor="currentPassword">Current Password</label>
            <input
              type="password"
              id="currentPassword"
              name="currentPassword"
              className="form-control"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.currentPassword}
            />
            {formik.touched && formik.errors.currentPassword && (
              <span className="help-block">
                {formik.errors.currentPassword}
              </span>
            )}
          </div>
          <div
            className={`form-group ${
              formik.touched && formik.errors.newPassword ? "has-error" : ""
            }`}
          >
            <label htmlFor="newPassword">New Password</label>
            <input
              type="password"
              id="newPassword"
              name="newPassword"
              className="form-control"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.newPassword}
            />
            {formik.touched && formik.errors.newPassword && (
              <span className="help-block">{formik.errors.newPassword}</span>
            )}
          </div>
          <div className="box-actions">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={loading}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
