const statusLogsApiCreator = (axios, config, interceptorHandler, apiCaller) => {
    const axiosInstance = axios.create(config);
    interceptorHandler(axiosInstance);

    return {
        downloadStatusLogsReport(from, to, riderIds) {
            return apiCaller(axiosInstance, {
                method: "POST",
                url: "/",
                headers: {
                    authorization: "Bearer " + localStorage.getItem("access_token"),
                    'Content-Type':"text/csv"
                },
                data: {
                    users: riderIds,
                    from,
                    to
                }
            })
        },
        getStatistics(){
            return apiCaller(axiosInstance,{
                method:"GET",
                url:"/statistics",
                headers: {
                    authorization: "Bearer " + localStorage.getItem("access_token")
                },
            })
        },
        getStatisticsByDate(date){
            return apiCaller(axiosInstance,{
                method:"POST",
                url:"/statistics-by-date",
                data:{
                    date
                },
                headers: {
                    authorization: "Bearer " + localStorage.getItem("access_token")
                },
            })
        }
    };
};

export default statusLogsApiCreator;
