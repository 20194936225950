import React, { useState, useEffect, useCallback } from "react";

import { useHistory, useParams } from "react-router-dom";
import { MOBILE_USER_ROUTES } from "constants/routes";

import { useFormik } from "formik";
import { getNewHexUserValidationSchema } from "./validationSchema";
import { hexUsersApi, HTTP_STATUS_CODES } from "api";

const NewUser = () => {
  const history = useHistory();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [apiErrors, setApiErros] = useState([]);
  const [apiMessage, setApiMessage] = useState("");
  const [phoneOSValue,setPhoneOSValue] = useState("android");
  const [tester,setTester] = useState("no");
  const [initialValues, setInitialValues] = useState({
    fullName: "",
    email: "",
    mobileNumber: ""
  });

  const initialize = (targetUser) => {
    setInitialValues({
      fullName: targetUser.fullName,
      mobileNumber: targetUser.mobileNumber,
      email: targetUser.email
    });
  }

  const getUserDetails = useCallback(() => {
    setLoading(true);
    hexUsersApi
      .getUserByAdmin(id)
      .execute()
      .then((result) => {
        console.log(result);
        if (result.data.hasError) {
          history.replace(MOBILE_USER_ROUTES.ROOT);
        } else {
          const payload = result.data.payload;
          setPhoneOSValue(payload.phoneOS);
          setTester(payload.tester);
          initialize(payload);
        }
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id) {
      getUserDetails();
    }
  }, [id, getUserDetails]);

  const onBackClicked = () => {
    history.push(MOBILE_USER_ROUTES.ROOT);
  };

  const saveUser = (apiFn, data) => {
    hexUsersApi
    [apiFn]({ ...data })
      .execute()
      .then((result) => {
        if (result.data.hasError) {
          if (
            result.data.statusCode === HTTP_STATUS_CODES.VALIDATION_ERROR
          ) {
            let validationErrors = [];
            Object.keys(result.data.errors).forEach((er) => {
              validationErrors = [
                ...validationErrors,
                ...result.data.errors[er],
              ];
            });
            setApiErros([...validationErrors]);
          } else {
            setApiErros([result.data.message]);
          }
        } else {
          if (id) {
            history.push(MOBILE_USER_ROUTES.ROOT);
          } else {
            setApiMessage("User created successfully");
            formik.resetForm();
          }

        }
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: getNewHexUserValidationSchema(),
    onSubmit: (values) => {
      const newValues = { ...values };
      if (!loading) {
        setApiErros([]);
        setLoading(true);
        saveUser(
          id ? "updateUserByAdmin" : "createUserByAdmin",
          id
            ? {
              ...newValues,
              id: id,
              phoneOS:phoneOSValue,
              tester: tester
            }
            : {
              ...values,
              phoneOS:phoneOSValue,
              tester: tester
            }
        );
      }
    },
  });

  const onPhoneOSChanged = (e)=>{
    console.log(e);
    setPhoneOSValue(e.currentTarget.value);
  }

  const onTesterChanged = (e)=>{
    setTester(e.currentTarget.value);
  }

  return (
    <>
      <div className="management-c-nav-actions">
        <button
          className="btn btn-default dropdown-toggle"
          type="button"
          onClick={onBackClicked}
        >
          Back to list
        </button>
      </div>
      <div className="management-c-body">
        <div className="df-box">
          <h3 className="box-title">{id ? "Update Mobile User" : "Create new Mobile User"} </h3>
          <div className="box-body">
            {!!apiErrors.length && (
              <div>
                <ul className="form-errors">
                  {apiErrors.map((ae) => (
                    <li key={ae}>{ae}</li>
                  ))}
                </ul>
              </div>
            )}
            {!!apiMessage && (
              <div className="form-success-message">{apiMessage}</div>
            )}
            <form onSubmit={formik.handleSubmit}>
              <div
                className={`form-group ${formik.touched.fullName && formik.errors.fullName
                  ? "has-error"
                  : ""
                  }`}
              >
                <label htmlFor="fullName">Full Name</label>
                <input
                  type="text"
                  id="fullName"
                  name="fullName"
                  className="form-control"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fullName}
                />
                {formik.touched.fullName && formik.errors.fullName && (
                  <span className="help-block">{formik.errors.fullName}</span>
                )}
              </div>
              <div
                className={`form-group ${formik.touched.email && formik.errors.email ? "has-error" : ""
                  }`}
              >
                <label htmlFor="email">Email Address</label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  className="form-control"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email && (
                  <span className="help-block">{formik.errors.email}</span>
                )}
              </div>
              <div
                className={`form-group ${formik.touched.mobileNumber && formik.errors.mobileNumber
                  ? "has-error"
                  : ""
                  }`}
              >
                <label htmlFor="mobileNumber">Mobile Number</label>
                <input
                  type="text"
                  id="mobileNumber"
                  name="mobileNumber"
                  className="form-control"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.mobileNumber}
                />
                {formik.touched.mobileNumber && formik.errors.mobileNumber && (
                  <span className="help-block">
                    {formik.errors.mobileNumber}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="phoneOS">Phone Type</label>
                <div>
                  <label style={{marginRight:16}}>
                    <input name="phoneOS" value="ios" type="radio" checked={phoneOSValue!=="android"}
                      style={{marginRight:4}}
                      onChange={onPhoneOSChanged}/>
                    IOS
                  </label>
                  <label>
                    <input name="phoneOS" value="android" type="radio" checked={phoneOSValue==="android"}
                    style={{marginRight:4}}
                      onChange={onPhoneOSChanged}/>
                    Android
                  </label>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="tester">Tester</label>
                <div>
                  <label style={{marginRight:16}}>
                    <input name="tester" value="yes" type="radio" checked={tester==="yes"}
                      style={{marginRight:4}}
                      onChange={onTesterChanged}/>
                    Yes
                  </label>
                  <label>
                    <input name="tester" value="no" type="radio" checked={tester!=="yes"}
                    style={{marginRight:4}}
                    onChange={onTesterChanged}/>
                    No
                  </label>
                </div>
              </div>

              <div className="box-actions">
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={loading}
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewUser;
