import React, { useEffect, useCallback, useState } from "react";
import { settingApi } from "api";


const BlockedPolicy = () => {
    const [loading, setLoading] = useState(false);
    const [policy, setPolicy] = useState("");
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");

    const getBlockedPolicy = useCallback(async () => {
        setLoading(true);
        settingApi
            .getBlockedPolicy()
            .execute()
            .then((result) => {
                console.log(result);
                if(!result.data.hasError){
                    setPolicy(result.data.payload.value);
                }
            })
            .finally(() => {
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        getBlockedPolicy();
    }, [getBlockedPolicy]);


    const onSaveClicked = async () => {
        setError("");
        if (policy) {
            if (!isNaN(parseInt(policy))) {
                setLoading(true);
                const result = await await settingApi.saveBlockedPolicy(policy).execute();
                setLoading(false);
                if (result.data.hasError) {
                    setError(result.data.message);
                } else {
                    setMessage("Policy saved.")
                }
            } else {
                setError("Policy Code not valid");
            }
        } else {
            setError("Policy Code is required");
        }
    };

    const onPolicyChanged = (e) => {
        setPolicy(e.target.value);
    }

    return (
        <>
            <div className="management-c-body">
                <div className="df-box">
                    <div className="box-body">
                        {
                            !!message && <div className="form-success-message">{message}</div>
                        }
                        <div
                            className="form-group"
                        >
                            <label htmlFor="maxLevel">Blocked Policy Code</label>
                            <input
                                type="text"
                                id="policy"
                                name="policy"
                                className="form-control"
                                onChange={onPolicyChanged}
                                value={policy}
                            />
                            {!!error && (
                                <span className="help-block">{error}</span>
                            )}
                        </div>
                        <div className="actions">
                            <button
                                className="btn btn-primary"
                                type="button"
                                disabled={loading}
                                onClick={onSaveClicked}
                            >
                                Save
                    </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BlockedPolicy;
