import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from "components/Modal";
import { vehicleApi } from "api";

const EditBeaconModal = ({
  opened,
  setOpened,
  beacon,
  onBeaconUpdated,
  beaconIndex
}) => {
  const [loading, setLoading] = useState(false);
  const [error,setError] = useState("");
  const [macAddress,setMacAddress] = useState(!!beacon ? beacon.macAddress : "");
  const [batteryLevel,setBatteryLevel] = useState(!!beacon ? beacon.batteryLevel : "");

  const onClose = () => {
    setOpened(false);
  };

  const onCancelClicked = () => {
    setOpened(false);
  };

  const onSaveClicked = ()=>{
    setError("");
    if(macAddress && batteryLevel){
      if(!(!isNaN(parseInt(batteryLevel)) && (parseInt(batteryLevel) >= 0 && parseInt(batteryLevel) <= 100))){
        setError("Battery level not valid");
        return;
      }
      console.log("here")

        setLoading(true);
        vehicleApi.updateBeacon({
            id:beaconIndex,
            macAddress:macAddress,
            batteryLevel:batteryLevel,
            details:!!beacon ? beacon.details : {},
            vehicleId:beacon.vehicleId
        }).execute()
            .then((result)=>{
                if(result.data.hasError){
                    setError(result.data.message);
                }else{
                    onBeaconUpdated({
                        ...beacon,
                        macAddress,
                        batteryLevel
                    },beaconIndex);
                    setOpened(false);
                }
            })
            .catch((err)=>{
                console.log(err);
                setLoading(false);
            })
    }
    
  }

  const onMacAddressChanged = (e)=>{
    setMacAddress(e.target.value);
  }

  const onBatteryLevelChanged = (e)=>{
    setBatteryLevel(e.target.value);
  }

  return (
    <Modal open={opened} onClose={onClose} className="create-modal">
      <ModalHeader>Edit MAC Address</ModalHeader>
        <ModalContent>
          <div className="df-box in-modal policy-modal">
            <div className="form-group">
              <label htmlFor="color">MAC Address</label>
              <input
                type="text"
                id="macAddress"
                name="macAddress"
                className="form-control"
                onChange={onMacAddressChanged}
                value={macAddress}
              />
              {
                  !!error && <span className="help-block">{error}</span>
              }
            </div>
            <div className="form-group">
              <label htmlFor="color">Battery Level</label>
              <input
                type="number"
                min="0"
                max="100"
                id="batteryLevel"
                name="batteryLevel"
                className="form-control"
                onChange={onBatteryLevelChanged}
                value={batteryLevel}
              />
              {
                  !!error && <span className="help-block">{error}</span>
              }
            </div>
          </div>
        </ModalContent>
        <ModalFooter>
          <div className="actions">
            <button
              className="btn btn-primary"
              type="button"
              disabled={loading}
              onClick={onSaveClicked}
            >
              Save
            </button>
            <button className="btn btn-danger" onClick={onCancelClicked}>
              Close
            </button>
          </div>
        </ModalFooter>
    </Modal>
  );
};

export default EditBeaconModal;
