import * as Yup from "yup";

export const getForgorPasswordValidationSchema = () => {
  return Yup.object({
    email: Yup.string()
      .required("Required")
      .email("Must be a valid Email address")
  });
};


export const getResetPasswordValidationSchema = () => {
    return Yup.object({
      password: Yup.string()
        .required("Required")
        .min(6,"Please enter at least 6 characters"),
      confirmPassword: Yup.string()
        .required("Required")
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
    });
  };