import React,{useState,useEffect} from "react";
import { authApi, HTTP_STATUS_CODES } from "api";
import { Link,useLocation,useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { getResetPasswordValidationSchema } from "./validationSchemas";

const ResetPassword = () => {
  const [apiErrors, setApiErros] = useState([]);
  const [apiMessage, setApiMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const params = new URLSearchParams(useLocation().search);
  const token = params.get("token");

  useEffect(()=>{
    console.log(token);
    if(!token){
      history.replace("/auth/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token])
  
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword:""
    },
    validationSchema: getResetPasswordValidationSchema(),
    onSubmit: (values) => {
      if (!loading) {
        setApiErros([]);
        setLoading(true);
        authApi
          .resetPassword(token,values.password)
          .execute()
          .then((result) => {
            if (result.data.hasError) {
              if (
                result.data.statusCode === HTTP_STATUS_CODES.VALIDATION_ERROR
              ) {
                let validationErrors = [];
                Object.keys(result.data.errors).forEach((er) => {
                  validationErrors = [
                    ...validationErrors,
                    ...result.data.errors[er],
                  ];
                });
                setApiErros([...validationErrors]);
              } else {
                setApiErros([result.data.message]);
              }
            } else {
              setApiMessage("Your Password successfuly changed");
              formik.resetForm();
            }
          })
          .finally(() => {
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  });

  return (
    <div className="login-box">
      {
        apiMessage ? (
          <div>
            <p>{apiMessage}</p>
            <Link to="/auth/login">Back to Login</Link>
          </div>
        ) : (
            <form onSubmit={formik.handleSubmit}>
              <h4 className="login-title">Forgot Password</h4>
              {!!apiErrors.length && (
                <div>
                  <ul className="form-errors">
                    {apiErrors.map((ae) => (
                      <li key={ae}>{ae}</li>
                    ))}
                  </ul>
                </div>
              )}
              <div className={`form-group ${formik.touched.password && formik.errors.password
                  ? "has-error"
                  : ""
                }`}>
                <label htmlFor="mobileNumber">New Password:</label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                />
                {formik.touched.password && formik.errors.password && (
                  <span className="help-block">{formik.errors.password}</span>
                )}
              </div>
              <div className={`form-group ${formik.touched.confirmPassword && formik.errors.confirmPassword
                  ? "has-error"
                  : ""
                }`}>
                <label htmlFor="mobileNumber">Confirm Password:</label>
                <input
                  type="password"
                  className="form-control"
                  id="confirmPassword"
                  name="confirmPassword"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmPassword}
                />
                {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                  <span className="help-block">{formik.errors.confirmPassword}</span>
                )}
              </div>
              <div className="form-group">
                <Link to="/auth/login">Back to Login</Link>
              </div>
              <div className="login-actions">
                <button className="btn btn-primary" 
                type="submit" 
                disabled={loading}>
                  Send
          </button>
              </div>
            </form>
          )
      }
    </div>
  );
};

export default ResetPassword;
