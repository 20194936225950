import { hexUsersApi, settingApi } from "api";
import React, { useCallback, useState, useEffect } from "react";
import { toast } from "shared/utils/toast";
import "./statuslogs.scss";
import dayjs from "dayjs";

const StatusLogs = () => {

  const [riders, setRiders] = useState([]);
  const [loadingRiders, setLoadingRiders] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [selectedRiders, setSelectedRiders] = useState([]);
  const [searchQ, setSearchQ] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [defaultTimeZone,setDefaultTimeZone] = useState("");

  const fetchTimeZone = useCallback(()=>{
    settingApi
            .getActiveZone()
            .execute()
            .then((result) => {
                if (!result.data.hasError) {
                  setDefaultTimeZone(result.data.payload.value);
                }
            })
            .catch((err) => {
                console.log(err);
            });
  },[]);

  useEffect(()=>{
    fetchTimeZone();
  },[fetchTimeZone])

  const fetchRiders = useCallback(() => {
    setLoadingRiders(true);
    hexUsersApi.getUsers(1000, 0)
      .execute()
      .then((res) => {
        console.log(res);
        if (!res.data.hasError) {
          setRiders(res.data.payload.users);
        }
      })
      .finally(() => {
        setLoadingRiders(false);
      });
  }, []);

  useEffect(() => {
    fetchRiders();
  }, [fetchRiders]);

  const onRiderClicked = (senderId) => {
    const tempSelectedRiders = [...selectedRiders];
    const targetIndex = tempSelectedRiders.indexOf(senderId);
    if (targetIndex >= 0) {
      tempSelectedRiders.splice(targetIndex, 1);
    } else {
      tempSelectedRiders.push(senderId);
    }
    setSelectedRiders(tempSelectedRiders);
  }

  const onSearchChanged = (e) => {
    setSearchQ(e.target.value);
  }

  const onStartDateChanged = (e) => {
    setStartDate(e.target.value);
  }

  const onEndDateChanged = (e) => {
    setEndDate(e.target.value);
  }

  const onDownloadClicked = () => {
    if (startDate && endDate) {
      if (startDate <= endDate) {
        let sRiders = [...selectedRiders];
        if (sRiders.length) {
          setDownloading(true);
          fetch(`${process.env.REACT_APP_BASE_API_ADDRESS}/status-logs/`, {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              authorization: "Bearer " + localStorage.getItem("access_token")
            },
            body:JSON.stringify({
              users:sRiders,
              from:`${startDate} 00:00:00`,
              to:`${endDate} 23:59:59`
            }),
            responseType: 'blob'
          }).then(response => response.blob())
            .then(data => {
              var a = document.createElement("a");
              a.href = window.URL.createObjectURL(data);
              a.download = `status_logs_${startDate}_${endDate}`;
              a.click();
            })
            .finally(() => {
              setDownloading(false);
            });
        } else {
          toast.error("Please select users.");
        }
      } else {
        toast.error("Entered Dates are not valid");
      }
    } else {
      toast.error("Enter Dates");
    }
  }

  const onSelectAllClicked = () => {
    if (selectedRiders.length === riders.length) {
      setSelectedRiders([]);
    } else {
      const tempSelectedRiders = riders.map(r => r.id);
      setSelectedRiders(tempSelectedRiders);
    }
  }

  return <div className="section-wrapper">
    <h3 className="section-title">Status Logs</h3>
    <div className="section-content">
    <div style={{marginBottom:16,color:"green",fontWeight:"500"}}>Active Time Zone: {defaultTimeZone}</div>
      <div className="status-logs">
        <div className="dates">
          <div>
            <label>From</label>
            <input type="date" className="form-control"
              value={startDate} onChange={onStartDateChanged} />
          </div>
          <div>
            <label>To</label>
            <input type="date" className="form-control"
              value={endDate} onChange={onEndDateChanged} />
          </div>
          <div>
            <button className="btn btn-primary btn-block btn-lg" type="button"
              onClick={onDownloadClicked}
              disabled={downloading}>
              {downloading ? "Downloading..." : "Download Report"}
            </button>
          </div>
        </div>
        <div className="riders">
          <div className="riders-actions">
            <h3>Select Users</h3>
            <div>
              <button type="button"
                onClick={onSelectAllClicked}>{selectedRiders.length === riders.length ? "Deselect All" : "Select All"}</button>
            </div>
          </div>
          <div className="search-input">
            <input type="text" placeholder="Search" className="form-control"
              value={searchQ} onChange={onSearchChanged} />
          </div>
          <div className="rider-list">
            {
              loadingRiders && <div>Loading Users...</div>
            }
            <ul>
              {
                riders.filter(rr => rr.fullName.toLowerCase().includes(searchQ.toLowerCase().trim())).map(r => (
                  <li key={r.id} className={selectedRiders.includes(r.id) ? "active-rider" : ""}
                    onClick={() => { onRiderClicked(r.id) }}>
                    <span></span>
                    <span>{r.fullName}</span>
                  </li>
                ))
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
}

export default StatusLogs;