import React from "react";

import "./index.scss";

import { Switch, Route, Redirect } from "react-router-dom";
import { LOGIN_ROUTES } from "constants/routes";

import NotFound from "components/NotFound";
import logo from "assets/img/df_logo.jpeg";

import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";

const LoginPage = () => {
  return (
    <div className="login-wrapper">
      <div className="df-logo-box">
        <img src={logo} alt="Distract Free" />
      </div>
      <Switch>
        <Route path={LOGIN_ROUTES.LOGIN} component={Login} />
        <Route path={LOGIN_ROUTES.FORGOT_PASSWORD} component={ForgotPassword} />
        <Route path={LOGIN_ROUTES.RESET_PASSWORD} component={ResetPassword} />
        <Redirect from="/auth" exact to={LOGIN_ROUTES.LOGIN} />
        <Route exact path="*" component={NotFound} />
      </Switch>
    </div>
  );
};

export default LoginPage;
