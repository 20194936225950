const vehicleApiCreator = (axios, config, interceptorHandler, apiCaller) => {
  const axiosInstance = axios.create(config);
  interceptorHandler(axiosInstance);

  return {
    createVehicle(data) {
      return apiCaller(axiosInstance, {
        method: "POST",
        url: "/",
        headers: {
          authorization: "Bearer " + localStorage.getItem("access_token"),
        },
        data,
      });
    },
    editVehicle(data) {
      return apiCaller(axiosInstance, {
        method: "PUT",
        url: "/",
        headers: {
          authorization: "Bearer " + localStorage.getItem("access_token"),
        },
        data,
      });
    },
    getVehicles(limit = 20,offset = 0) {
      return apiCaller(axiosInstance, {
        method: "GET",
        url: `/?limit=${limit}&offset=${offset}`,
        headers: {
          authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      });
    },
    getVehicleById(id) {
      return apiCaller(axiosInstance, {
        method: "GET",
        url: "/" + id,
        headers: {
          authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      });
    },
    getOccupants(vehicleId){
      return apiCaller(axiosInstance, {
        method: "GET",
        url: "/" + vehicleId + "/occupants",
        headers: {
          authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      });
    },
    updateBeacon(beacon){
      console.log(beacon);
      return apiCaller(axiosInstance, {
        method: "PUT",
        url: "/" + beacon.vehicleId + "/beacons/" + beacon.id ,
        data:beacon,
        headers: {
          authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      });
    },
    deleteVehicle(id){
      return apiCaller(axiosInstance, {
        method: "DELETE",
        url: "/" + id,
        headers: {
            authorization: "Bearer " + localStorage.getItem("access_token"),
        }
    });
    },
    vehicleSearch(searchTerm) {
      return apiCaller(axiosInstance, {
        method: "GET",
        url: `/search/${searchTerm}`,
        headers: {
          authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      });
    }
  };
};

export default vehicleApiCreator;
