import React from "react";
import { Switch, Route } from "react-router-dom";
import { MOBILE_USER_ROUTES } from "constants/routes";

import UserList from "./UserList";
import NewUser from "./NewUser";

const MobileUsersManagement = () => {
  return (
    <div className="management-container">
      <Switch>
        <Route path={MOBILE_USER_ROUTES.ENROLL} component={NewUser} />
        <Route path={MOBILE_USER_ROUTES.UPDATE} component={NewUser} />
        <Route path={MOBILE_USER_ROUTES.ROOT} component={UserList} />
      </Switch>
    </div>
  );
};

export default MobileUsersManagement;
