const foregroundAppsApiCreator = (axios, config, interceptorHandler, apiCaller) => {
    const axiosInstance = axios.create(config);
    interceptorHandler(axiosInstance);
  
    return {
      createForegroundApp(data) {
        return apiCaller(axiosInstance, {
          method: "POST",
          url: "/",
          headers: {
            authorization: "Bearer " + localStorage.getItem("access_token"),
          },
          data,
        });
      },
      editForegroundApp(data) {
        return apiCaller(axiosInstance, {
          method: "PUT",
          url: "/",
          headers: {
            authorization: "Bearer " + localStorage.getItem("access_token"),
          },
          data,
        });
      },
      getForegroundAppById(id) {
        return apiCaller(axiosInstance, {
          method: "GET",
          url: "/" + id,
          headers: {
            authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        });
      },
      getForegroundApps(limit = 20,offset = 0) {
        return apiCaller(axiosInstance, {
          method: "GET",
          url: `/?limit=${limit}&offset=${offset}`,
          headers: {
            authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        });
      },
      deleteForegroundApp(id){
        return apiCaller(axiosInstance, {
          method: "DELETE",
          url: "/" + id,
          headers: {
              authorization: "Bearer " + localStorage.getItem("access_token"),
          }
      });
      }
    };
  };
  
  export default foregroundAppsApiCreator;
  