import React from "react";
import {ActionsProvider} from "./ActionsContext";
const ActionsDropDown = ({children}) => {

  return (
    <ActionsProvider>
        {children}
    </ActionsProvider>
  );
};

export default ActionsDropDown;
