import { settingApi, statusLogsApi } from "api";
import React, { useCallback, useEffect, useState } from "react";

const StatusStatistics = () => {
  const [data, setData] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingCustomDate, setLoadingCustomDate] = useState(false);
  const [defaultTimeZone,setDefaultTimeZone] = useState("");

  const fetchTimeZone = useCallback(()=>{
    settingApi
            .getActiveZone()
            .execute()
            .then((result) => {
                if (!result.data.hasError) {
                  setDefaultTimeZone(result.data.payload.value);
                }
            })
            .catch((err) => {
                console.log(err);
            });
  },[]);

  useEffect(()=>{
    fetchTimeZone();
  },[fetchTimeZone])

  const fetchData = useCallback(() => {
    setLoading(true);
    statusLogsApi.getStatistics()
      .execute()
      .then((res) => {
        console.log(res);
        if (!res.data.hasError) {
          setData(res.data.payload)
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData])

  const onDateChanged = (e) => {
    setSelectedDate(e.target.value);
    console.log(e.target.value);
    const value = e.target.value;
    if (value >= '2022-01-01') {
      setLoadingCustomDate(true);
      statusLogsApi.getStatisticsByDate(value)
        .execute()
        .then((res) => {
          console.log(res.data.payload);
          if (!res.data.hasError) {
            const result = res.data.payload;
            const tempData = [...data];
            for (let i = 0; i < tempData.length; i++) {
              tempData[i].customData = result[i];
            }
            setData(tempData);
          } else {
            const tempData = [...data];
            for (let i = 0; i < tempData.length; i++) {
              tempData[i].customData = null;
            }
            setData(tempData);
          }
        })
        .finally(() => {
          setLoadingCustomDate(false);
        });
    } else {
      const tempData = [...data];
      for (let i = 0; i < tempData.length; i++) {
        tempData[i].customData = null;
      }
      setData(tempData);
    }
  }



  return <div className="section-wrapper">
    <h3 className="section-title">Status Statistics</h3>
    <div className="section-content">
      <div style={{marginBottom:16,color:"green",fontWeight:"500"}}>Active Time Zone: {defaultTimeZone}</div>
      <table className="table table-responsive ">
        <thead>
          <tr>
            <th>Metric</th>
            <th>Last Work Day</th>
            <th>Past Week</th>
            <th>Past Month</th>
            <th>
              <div>Since</div>
              <div>
                <input type="date" className="form-control"
                  value={selectedDate} onChange={onDateChanged} />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {
            loading && <tr><td>Loading...</td></tr>
          }
          {
            data.map(item => (
              <tr key={item.metric}>
                <td>{item.metricTitle}</td>
                <td>{roundDecimal(item.lastDay.value)} {item.lastDay.isPercent ? "%" : " Min"}</td>
                <td>{roundDecimal(item.lastWeek.value)} {item.lastWeek.isPercent ? "%" : " Min"}</td>
                <td>{roundDecimal(item.lastMonth.value)} {item.lastMonth.isPercent ? "%" : " Min"}</td>
                <td>
                  {
                    loadingCustomDate ? <span>Loading...</span> : (
                      <span>
                        {item.customData && roundDecimal(item.customData.value)} {item.customData && (item.customData.isPercent ? "%" : " Min")}
                      </span>
                    )
                  }
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  </div>
}

export const roundDecimal = function(number) {
  if (number.toFixed(4).replace(/0+$/, "") === "0.") {
    return 0;
  }
  return number.toFixed(4).replace(/0+$/, "");
}

export default StatusStatistics;
