import React, { useEffect, useCallback, useState } from "react";
import { settingApi } from "api";
import CreateBatteryLevelModal from "./CreateBatteryLevelModal";
import "./index.scss";

const defaultBatteryData = {
  id: null,
  maxLevel: 0,
  hint: "",
  color: "",
};
const BatterySetting = () => {
  const [loading, setLoading] = useState(false);
  const [batteryLevels, setBatteryLevels] = useState([]);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [targetRecord, setTargetRecord] = useState({
    ...defaultBatteryData,
  });

  const getBatteryLevels = useCallback(async () => {
    setLoading(true);
    settingApi
      .getBatteryLevels()
      .execute()
      .then((result) => {
        console.log(result);
        if (!result.data.hasError) {
          setBatteryLevels(result.data.payload);
        }
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getBatteryLevels();
  }, [getBatteryLevels]);

  const onEnrollClicked = () => {
    setOpenCreateModal(true);
  };

  const onBatterylLevelSaved = (data) => {
    setOpenCreateModal(false);
    if (targetRecord.id) {
      let targetItem = batteryLevels.findIndex((b) => b.id === targetRecord.id);
      let tempItems = [...batteryLevels];
      tempItems[targetItem] = {
        ...data,
      };
      setBatteryLevels([...tempItems]);
    } else {
      setBatteryLevels([...batteryLevels, data]);
    }
    setTargetRecord({ ...defaultBatteryData });
  };

  const editItem = (sender) => {
    console.log(sender);
    setTargetRecord({ ...sender });
    setOpenCreateModal(true);
  };

  return (
    <>
      <div className="management-c-nav-actions">
        <button
          className="btn btn-default dropdown-toggle"
          type="button"
          onClick={onEnrollClicked}
        >
          New
        </button>
      </div>
      <div className="management-c-body">
        <div className="df-table-wrapper">
          {loading ? (
            <div>Loading...</div>
          ) : (
            <table className="table table-responsive ">
              <thead>
                <tr>
                  <th>Max Level</th>
                  <th>Hint</th>
                  <th>Color</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {batteryLevels.map((b) => (
                  <tr key={b.id}>
                    <td>{b.maxLevel}</td>
                    <td>{b.hint}</td>
                    <td>{b.color}</td>
                    <td>
                      <button
                        className="btn"
                        onClick={() => {
                          editItem(b);
                        }}
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      {openCreateModal && (
        <CreateBatteryLevelModal
          opened={openCreateModal}
          setOpened={setOpenCreateModal}
          initialData={targetRecord}
          onSave={onBatterylLevelSaved}
        />
      )}
    </>
  );
};

export default BatterySetting;
