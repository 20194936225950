import React, { useState, useEffect } from "react";
import { useGlobal } from "globalContext";
import { authApi, HTTP_STATUS_CODES } from "api";
import { Link, useHistory } from "react-router-dom";

const Login = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [apiErrors, setApiErros] = useState([]);
  const history = useHistory();
  const { setUser } = useGlobal();
  const [loginIsSuccessful, setLoginIsSuccessful] = useState(false);
  const onMobileNumberChanged = (e) => {
    setMobileNumber(e.target.value);
  };

  const onPasswordChanged = (e) => {
    setPassword(e.target.value);
  };

  useEffect(() => {
    if (loginIsSuccessful) {
      history.push("/panel/manage/vehicles");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginIsSuccessful]);

  const onFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    authApi
      .login({ mobileNumber, password })
      .execute()
      .then((result) => {
        if (!result.data.hasError) {
          const {
            fullName,
            email,
            mobileNumber,
            status,
            roles,
          } = result.data.payload;
          console.log("here")
          localStorage.setItem("access_token", result.data.payload.token);
          setUser({ fullName, email, mobileNumber, status, roles });
          setLoginIsSuccessful(true);
        } else {
          if (result.data.statusCode === HTTP_STATUS_CODES.VALIDATION_ERROR) {
            let validationErrors = [];
            Object.keys(result.data.errors).forEach((er) => {
              validationErrors = [
                ...validationErrors,
                ...result.data.errors[er],
              ];
            });
            setApiErros([...validationErrors]);
          } else {
            setApiErros([result.data.message]);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="login-box">
      <form onSubmit={onFormSubmit}>
        <h4 className="login-title">Login</h4>
        {!!apiErrors.length && (
          <div>
            <ul className="form-errors">
              {apiErrors.map((ae) => (
                <li key={ae}>{ae}</li>
              ))}
            </ul>
          </div>
        )}
        <div className="form-group">
          <label htmlFor="mobileNumber">Mobile Number:</label>
          <input
            type="text"
            className="form-control"
            id="mobileNumber"
            name="mobileNumber"
            value={mobileNumber}
            onChange={onMobileNumberChanged}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            className="form-control"
            id="password"
            name="password"
            value={password}
            onChange={onPasswordChanged}
          />
        </div>
        <div className="form-group">
          <Link to="/auth/forgot-password">Forgot Password?</Link>
        </div>
        <div className="login-actions">
          <button className="btn btn-primary" type="submit" disabled={loading}>
            Login
          </button>
        </div>
      </form>
    </div>
  );
};

export default Login;
