export const MAIN_ROUTES = {
  ROOT: "/",
  LOGIN: "/auth/",
  PANEL: "/panel",
  MANAGEMENT: "/panel/manage",
  STATUS_LOGS:"/panel/status-logs",
  STATUS_STATISTICS:"/panel/status-statistics"
};

export const USER_MANAGEMENT_ROUTES = {
  ROOT: "/panel/manage/users",
  ENROLL: "/panel/manage/users/enroll",
  UPDATE:"/panel/manage/users/:id/update",
};
export const VEHICLE_MANAGEMENT_ROUTES = {
  ROOT: "/panel/manage/vehicles",
  NEW: "/panel/manage/vehicles/new",
  EDIT: "/panel/manage/vehicles/:id/edit",
  VIEW: "/panel/manage/vehicles/:id",
};

export const MOBILE_USER_ROUTES = {
  ROOT: "/panel/manage/mobile-users",
  ENROLL: "/panel/manage/mobile-users/enroll",
  UPDATE: "/panel/manage/mobile-users/:id/update",
};

export const FOREGROUND_APP_MANAGEMENT_ROUTES = {
  ROOT: "/panel/manage/foreground-apps",
  NEW: "/panel/manage/foreground-apps/new",
  EDIT: "/panel/manage/foreground-apps/:id/edit",
};

export const SETTING_ROUTES = {
  ROOT: "/panel/setting",
  POLICIES: "/panel/setting/policies",
  BATTERY: "/panel/setting/battery",
  SHIFT_HOURS: "/panel/setting/shift-hours",
  ZONE: "/panel/setting/zone",
  NOTIFICATION_DETAILS:"/panel/setting/notifications"
};

export const ACCOUNT_ROUTES = {
  ROOT: "/panel/account",
  CHANGE_PASSWORD: "/panel/account/change-password",
  EDIPT_PROFILE: "/panel/account/profile",
};

export const LOGIN_ROUTES = {
  LOGIN: "/auth/login",
  FORGOT_PASSWORD: "/auth/forgot-password",
  RESET_PASSWORD: "/auth/reset-password",
};
