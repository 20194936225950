import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from "components/Modal";
import { useFormik } from "formik";
import { settingApi, HTTP_STATUS_CODES } from "api";
import { getCreateBatteryLevelSchema } from "./batteryLevelSchema";

const CreateBatteryLevelModal = ({
  opened,
  setOpened,
  onSave,
  initialData,
}) => {
  const [loading, setLoading] = useState(false);
  const [apiErrors, setApiErrors] = useState([]);

  const saveBatteryLevel = (apiFn, data) => {
    setApiErrors([]);
    setLoading(true);

    settingApi[apiFn](data)
      .execute()
      .then((result) => {
        if (result.data.hasError) {
          if (result.data.statusCode === HTTP_STATUS_CODES.VALIDATION_ERROR) {
            let validationErrors = [];
            Object.keys(result.data.errors).forEach((er) => {
              validationErrors = [
                ...validationErrors,
                ...result.data.errors[er],
              ];
            });
            setApiErrors([...validationErrors]);
          } else {
            setApiErrors([result.data.message]);
          }
        } else {
          onSave &&
            onSave(
              initialData.id
                ? { ...data }
                : { ...data, id: result.data.payload.id }
            );
          formik.resetForm();
        }
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const formik = useFormik({
    initialValues: {
      maxLevel: initialData.maxLevel,
      hint: initialData.hint,
      color: initialData.color,
    },
    validationSchema: getCreateBatteryLevelSchema(),
    onSubmit: (values) => {
      console.log(values);
      if (!loading) {
        if (!initialData.id) {
          saveBatteryLevel("createBatteryLevel", {
            maxLevel: values.maxLevel,
            hint: values.hint,
            color: values.color,
          });
        } else {
          saveBatteryLevel("editBatteryLevel", {
            ...initialData,
            maxLevel: values.maxLevel,
            hint: values.hint,
            color: values.color,
          });
        }
      }
    },
  });

  const onClose = () => {
    setOpened(false);
  };

  const onCancelClicked = () => {
    setOpened(false);
  };

  return (
    <Modal open={opened} onClose={onClose} className="create-modal">
      <ModalHeader>Battery Level</ModalHeader>
      <form noValidate onSubmit={formik.handleSubmit}>
        <ModalContent>
          <div className="df-box in-modal">
            {!!apiErrors.length && (
              <div>
                <ul className="form-errors">
                  {apiErrors.map((ae) => (
                    <li key={ae}>{ae}</li>
                  ))}
                </ul>
              </div>
            )}
            <div
              className={`form-group ${
                formik.touched.maxLevel && formik.errors.maxLevel
                  ? "has-error"
                  : ""
              }`}
            >
              <label htmlFor="maxLevel">Max Level</label>
              <input
                type="text"
                id="maxLevel"
                name="maxLevel"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.maxLevel}
              />
              {formik.touched.maxLevel && formik.errors.maxLevel && (
                <span className="help-block">{formik.errors.maxLevel}</span>
              )}
            </div>
            <div
              className={`form-group ${
                formik.touched.hint && formik.errors.hint ? "has-error" : ""
              }`}
            >
              <label htmlFor="hint">Hint</label>
              <input
                type="text"
                id="hint"
                name="hint"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.hint}
              />
              {formik.touched.hint && formik.errors.hint && (
                <span className="help-block">{formik.errors.hint}</span>
              )}
            </div>
            <div
              className={`form-group ${
                formik.touched.color && formik.errors.color ? "has-error" : ""
              }`}
            >
              <label htmlFor="color">Color</label>
              <input
                type="color"
                id="color"
                name="color"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.color}
              />
              {formik.touched.color && formik.errors.color && (
                <span className="help-block">{formik.errors.color}</span>
              )}
            </div>
          </div>
        </ModalContent>
        <ModalFooter>
          <div className="actions">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={loading}
            >
              Save
            </button>
            <button className="btn btn-danger" onClick={onCancelClicked}>
              Cancel
            </button>
          </div>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default CreateBatteryLevelModal;
