import React, { useEffect, useState } from "react";

import { useFormik } from "formik";
import { getUpdateProfileSchema } from "./validationSchema";
import { authApi, HTTP_STATUS_CODES } from "api";

import { useGlobal } from "globalContext";
import { NotificationSettings } from "./NotificationSettings";

const Profile = () => {
  const { user, setUser } = useGlobal();
  const [loading, setLoading] = useState(false);
  const [apiErrors, setApiErros] = useState([]);
  const [apiMessage, setApiMessage] = useState("");

  const [initialValues, setInitialValues] = useState({
    fullName: user.fullName,
    email: user.email,
    mobileNumber: user.mobileNumber,
  });

  const initialize = (targetUser) => {
    setInitialValues({
      fullName: targetUser.fullName,
      mobileNumber: targetUser.mobileNumber,
      email: targetUser.email
    });
  }

  useEffect(() => {
    initialize(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);


  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: getUpdateProfileSchema(),
    onSubmit: (values) => {
      if (!loading) {
        setApiErros([]);
        setLoading(true);
        authApi
          .updateProfile(values)
          .execute()
          .then((result) => {
            if (result.data.hasError) {
              if (
                result.data.statusCode === HTTP_STATUS_CODES.VALIDATION_ERROR
              ) {
                let validationErrors = [];
                Object.keys(result.data.errors).forEach((er) => {
                  validationErrors = [
                    ...validationErrors,
                    ...result.data.errors[er],
                  ];
                });
                setApiErros([...validationErrors]);
              } else {
                setApiErros([result.data.message]);
              }
            } else {
              setApiMessage("Profile updated successfully");
              setUser({
                ...user,
                fullName:values.fullName,
                mobileNumber:values.mobileNumber,
                email:values.email
              });
            }
          })
          .finally(() => {
            setLoading(false);
          })
      }
    },
  });

  return (
    <>
      <div className="management-c-nav-actions">
      </div>
      <div className="management-c-body">
        <div className="df-box">
          <h3 className="box-title">Update Profile</h3>
          <div className="box-body">
            {!!apiErrors.length && (
              <div>
                <ul className="form-errors">
                  {apiErrors.map((ae) => (
                    <li key={ae}>{ae}</li>
                  ))}
                </ul>
              </div>
            )}
            {!!apiMessage && (
              <div className="form-success-message">{apiMessage}</div>
            )}
            <form onSubmit={formik.handleSubmit}>
              <div
                className={`form-group ${formik.touched.fullName && formik.errors.fullName
                  ? "has-error"
                  : ""
                  }`}
              >
                <label htmlFor="currentPassword">Full Name</label>
                <input
                  type="text"
                  id="fullName"
                  name="fullName"
                  className="form-control"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fullName}
                />
                {formik.touched.fullName && formik.errors.fullName && (
                  <span className="help-block">{formik.errors.fullName}</span>
                )}
              </div>
              <div
                className={`form-group ${formik.touched.email && formik.errors.email ? "has-error" : ""
                  }`}
              >
                <label htmlFor="currentPassword">Email Address</label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  className="form-control"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email && (
                  <span className="help-block">{formik.errors.email}</span>
                )}
              </div>
              <div
                className={`form-group ${formik.touched.mobileNumber && formik.errors.mobileNumber
                  ? "has-error"
                  : ""
                  }`}
              >
                <label htmlFor="currentPassword">Mobile Number</label>
                <input
                  type="text"
                  id="mobileNumber"
                  name="mobileNumber"
                  className="form-control"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.mobileNumber}
                />
                {formik.touched.mobileNumber && formik.errors.mobileNumber && (
                  <span className="help-block">
                    {formik.errors.mobileNumber}
                  </span>
                )}
              </div>

              <div className="box-actions">
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={loading}
                >
                  Save
              </button>
              </div>
            </form>
          </div>
        </div>
        <NotificationSettings data={user.notificationSettings}/>
      </div>
    </>
  );
};

export default Profile;
