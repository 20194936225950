import React, { useEffect, useCallback } from "react";
import { settingApi } from "api";

const defaultData = "No"

const ReminderItem = ({remindIOS, setRemindIOS, setLoading }) => {

  const handleChange = (e) => {
    setRemindIOS(e.currentTarget.value)
  }

  const getRemindIOS = useCallback(() => {
    setLoading(true);
    settingApi
      .getReminderIOS()
      .execute()
      .then((result) => {
        if (!result.data.hasError) {
          setRemindIOS(result.data.payload.value);
        } else {
          setRemindIOS(defaultData);
        }
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getRemindIOS();
  }, [getRemindIOS]);
  
    return ( 
      <div>
      <label>Remind iOS user when in moving vehicle?</label>
        <div> 
          <input
              id="No"
              type="radio"
              value="No"
              name="remindIOS"
              checked={remindIOS === "No"}
              onChange={handleChange}
              style={{marginRight:5}}
            />
          <label style={{marginRight:10}}>No</label>
          <input
            id="Yes"
            type="radio"
            value="Yes"
            name="remindIOS"
            checked={remindIOS === "Yes"}
            onChange={handleChange}
            style={{marginRight:5}}
          />
          <label>Yes</label>
        </div>
      </div>
     )
};

export default ReminderItem;