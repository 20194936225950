import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from "components/Modal";
import { hexUsersApi } from "api";
import "./index.scss";

const PolicyModal = ({
  opened,
  setOpened,
  hexUserId,
  currentPolicy,
  onUpdated
}) => {
  const [loading, setLoading] = useState(false);
  const [error,setError] = useState("");
  const [message,setMessage] = useState("");
  const [assignFlag,setAssignFlag] = useState(true);
  const [policyCode,setPolicyCode] = useState(!!currentPolicy? currentPolicy:"");
  const onClose = () => {
    setOpened(false);
  };

  const onCancelClicked = () => {
    setOpened(false);
  };

  const onSendClicked = ()=>{
    setError("");
    setMessage("");
    if(assignFlag){
      if(!policyCode || isNaN(parseInt(policyCode))){
        setError("Value not valid");
        return;
      }
      assignPolicy(policyCode);
    }else{
      removePolicy();
    }
  }

  const assignPolicy = (numbers)=>{
    setLoading(true);
    hexUsersApi.assignPolicy(hexUserId,numbers)
      .execute()
      .then((result)=>{
        if(!result.data.hasError){
          setMessage(result.data.message);
          if(onUpdated){
            onUpdated(hexUserId,policyCode);
          }
        }
      })
      .finally(()=>{
        setLoading(false);
      });
  }

  const removePolicy = ()=>{
    setLoading(true);
    hexUsersApi.removePolicy(hexUserId)
      .execute()
      .then((result)=>{
        if(!result.data.hasError){
          setMessage(result.data.message);
          if(onUpdated){
            onUpdated(hexUserId,null);
          }
        }
      })
      .finally(()=>{
        setLoading(false);
      });
  }

  const onPoliciesChanged = (e)=>{
    setPolicyCode(e.target.value);
  }

  const onAssignFlagChanged = (e)=>{
    setAssignFlag(e.target.checked);
    if(!e.target.checked){
      setPolicyCode("")
    }
  }

  return (
    <Modal open={opened} onClose={onClose} className="create-modal">
      <ModalHeader>Assign Or Remove Policy</ModalHeader>
        <ModalContent>
          <div className="df-box in-modal policy-modal">
            {
              !!message && <div className="form-success-message">{message}</div>
            }
            <div className="form-group">
              <label htmlFor="color">Block Policy Code</label>
              <input
                type="number"
                id="policies"
                name="policies"
                className="form-control"
                onChange={onPoliciesChanged}
                value={policyCode}
                disabled={!assignFlag}
              />
              {
                  !!error && <span className="help-block">{error}</span>
              }
            </div>
            <div className="form-group frm-chk-box">
                <label>
                    <input type="checkbox" checked={assignFlag}
                      onChange={onAssignFlagChanged}/>
                    Assign Policy
                </label>
            </div>
          </div>
        </ModalContent>
        <ModalFooter>
          <div className="actions">
            <button
              className="btn btn-primary"
              type="button"
              disabled={loading}
              onClick={onSendClicked}
            >
              {assignFlag ? "Assign Policy" : "Remove Policy"}
            </button>
            <button className="btn btn-danger" onClick={onCancelClicked}>
              Close
            </button>
          </div>
        </ModalFooter>
    </Modal>
  );
};

export default PolicyModal;
