import React, { useEffect, useCallback, useState } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import { FOREGROUND_APP_MANAGEMENT_ROUTES } from "constants/routes";

import { foregroundAppApi } from "api";
import Pagination from "components/Pagination";
import { PAGE_RECORDS } from 'constants/pagination';

import ActionsDropDown from "components/ActionsDropDown";
import ActionItem from "components/ActionsDropDown/ActionItem";

const AppList = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [foregroundApps, setForegroundApps] = useState([]);
  const params = new URLSearchParams(useLocation().search);
  const currentUrlPage = params.get("page");
  const currentPage = currentUrlPage && parseInt(currentUrlPage) ? parseInt(currentUrlPage) : 1;
  const [pageCount, setPageCount] = useState(1);


  const onEnrollClicked = () => {
    history.push(FOREGROUND_APP_MANAGEMENT_ROUTES.NEW);
  };

  const getForegroundApps = useCallback(async () => {
    let limit = PAGE_RECORDS;
    let offset = (currentPage - 1) * PAGE_RECORDS;
    setLoading(true);
    foregroundAppApi
        .getForegroundApps(limit, offset)
        .execute()
        .then((result) => {
        if (!result.data.hasError) {
            console.log(result.data.payload.foregroundApps)
            setForegroundApps(result.data.payload.foregroundApps);
            const totalCount = result.data.payload.totalCount;
            if (totalCount) {
            if (totalCount > PAGE_RECORDS) {
                setPageCount(Math.ceil(totalCount / PAGE_RECORDS))
            }
            }
        }
        })
        .finally(() => {
        setLoading(false);
        })
        .catch((err) => {
        console.log(err);
        });

  }, [currentPage]);

  useEffect(() => {
    getForegroundApps();
  }, [getForegroundApps]);

  const onPageChanged = (page) => {
    history.push(`${FOREGROUND_APP_MANAGEMENT_ROUTES.ROOT}?page=${page}`);
  }

  return (
    <>
      <div className="management-c-nav-actions">
        <button
          className="btn btn-default dropdown-toggle"
          type="button"
          onClick={onEnrollClicked}
        >
          Add
        </button>
      </div>
      <div className="management-c-body">
        <div className="df-table-wrapper">
          {loading ? (
            <div>Loading...</div>
          ) : (
            <>
              <table className="table table-responsive ">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Black Listed</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {foregroundApps.map((v) => (
                    <tr key={v.id}>
                      <td>{v.appName}</td>
                      <td>{v.blackListed ? "Yes" : "No"}</td>
                      <td>
                          <ActionsDropDown>
                            <ActionItem onClick={() => { }}>
                              <Link
                                to={`/panel/manage/foreground-apps/${v.id}/edit`}
                                className="lnk"
                              >
                                Edit
                              </Link>
                            </ActionItem>
                          </ActionsDropDown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {
                (pageCount > 1) && <Pagination currentPage={currentPage} pageCount={pageCount} onPageChanged={onPageChanged} />
              }
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AppList;
