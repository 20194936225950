import React from "react";
import "./index.scss";

export const ModalHeader = ({ children }) => {
  return <div className="m-header">{children}</div>;
};

export const ModalContent = ({ children }) => {
  return <div className="m-content">{children}</div>;
};

export const ModalFooter = ({ children }) => {
  return <div className="m-footer">{children}</div>;
};

export const Modal = ({ children, onClose, open, className = "" }) => {
  const closeModal = () => {
    onClose();
  };

  const onBackdropClicked = () => {
    closeModal();
  };
  return open ? (
    <div className="df-modal">
      <div className={`modal-window ${className}`}>{children}</div>
      <div className="modal-backdrop" onClick={onBackdropClicked}></div>
    </div>
  ) : null;
};
