import React, { useState } from "react";
import { authApi, HTTP_STATUS_CODES } from "api";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { getForgorPasswordValidationSchema } from "./validationSchemas";

const ForgotPassword = () => {
  const [apiErrors, setApiErros] = useState([]);
  const [apiMessage, setApiMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: getForgorPasswordValidationSchema(),
    onSubmit: (values) => {
      if (!loading) {
        setApiErros([]);
        setLoading(true);
        authApi
          .sendForgotPasswordEmail(values.email)
          .execute()
          .then((result) => {
            if (result.data.hasError) {
              if (
                result.data.statusCode === HTTP_STATUS_CODES.VALIDATION_ERROR
              ) {
                let validationErrors = [];
                Object.keys(result.data.errors).forEach((er) => {
                  validationErrors = [
                    ...validationErrors,
                    ...result.data.errors[er],
                  ];
                });
                setApiErros([...validationErrors]);
              } else {
                setApiErros([result.data.message]);
              }
            } else {
              setApiMessage("Reset Password link sent to your entered email address, If you did not receive the email, please check your spams.");
              formik.resetForm();
            }
          })
          .finally(() => {
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  });

  return (
    <div className="login-box">
      {
        apiMessage ? (
          <div>{apiMessage}</div>
        ) : (
            <form onSubmit={formik.handleSubmit}>
              <h4 className="login-title">Forgot Password</h4>
              {!!apiErrors.length && (
                <div>
                  <ul className="form-errors">
                    {apiErrors.map((ae) => (
                      <li key={ae}>{ae}</li>
                    ))}
                  </ul>
                </div>
              )}
              <div className={`form-group ${formik.touched.email && formik.errors.email
                  ? "has-error"
                  : ""
                }`}>
                <label htmlFor="mobileNumber">E-mail:</label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email && (
                  <span className="help-block">{formik.errors.email}</span>
                )}
              </div>
              <div className="form-group">
                <Link to="/auth/login">Back to Login</Link>
              </div>
              <div className="login-actions">
                <button className="btn btn-primary" type="submit" disabled={loading}>
                  Send
          </button>
              </div>
            </form>
          )
      }
    </div>
  );
};

export default ForgotPassword;
