import React from "react";
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from "components/Modal";

const ConfirmModal = ({
  opened,
  setOpened,
  title,
  description,
  onConfirm
}) => {

  const onClose = () => {
    setOpened(false);
  };

  const onCancelClicked = () => {
    setOpened(false);
  };

  const onConfirmClicked = ()=>{
    if(onConfirm){
        onConfirm();
    }    
  }

  return (
    <Modal open={opened} onClose={onClose} className="create-modal">
      {title && <ModalHeader>{title}</ModalHeader>}
        <ModalContent>
          <div className="df-box in-modal policy-modal">
            <div style={{textAlign:"center"}}>
            <div>{description || "Are you sure?"}</div>
            </div>
          </div>
        </ModalContent>
        <ModalFooter>
          <div className="actions">
            <button
              className="btn btn-primary"
              type="button"
              onClick={onConfirmClicked}
            >
              Yes
            </button>
            <button className="btn btn-danger" onClick={onCancelClicked}>
              No
            </button>
          </div>
        </ModalFooter>
    </Modal>
  );
};

export default ConfirmModal;
