import React from "react";
import "./index.scss";
import UserBox from "../UserBox";
import { NavLink } from "react-router-dom";
import { MAIN_ROUTES, SETTING_ROUTES } from "constants/routes";
import logo from "assets/img/df_logo.jpeg";

const MainNav = () => {
  return (
    <div className="main-nav">
      <div className="nav-items">
        <div className="nav-logo">
          <img src={logo} alt="Distract Free" />
          <span>DistractFree</span>
        </div>
        <ul className="nav-links">
        <li>
            <NavLink to={MAIN_ROUTES.STATUS_LOGS} className="nav-link-item">
              Status Logs
            </NavLink>
          </li>
          <li>
            <NavLink to={MAIN_ROUTES.STATUS_STATISTICS} className="nav-link-item">
              Status Statistics
            </NavLink>
          </li>
          <li>
            <NavLink to={MAIN_ROUTES.MANAGEMENT} className="nav-link-item">
              Manage
            </NavLink>
          </li>
          <li>
            <NavLink to={SETTING_ROUTES.ROOT} className="nav-link-item">
              Settings
            </NavLink>
          </li>
        </ul>
      </div>
      <UserBox />
    </div>
  );
};

export default MainNav;
